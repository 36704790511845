import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplate.scss';
import '@teneleven/protocols-ts-web';

import { ConverterLayer, buildingPlanStruct, SiteType, BuildingTypeData, LayerType, selectHouse, mapProjectionData, DEMData, ListType, ConverterType } from './DataTypes';
import { ReactComponent as MySite } from '../img/CADConverter/mySite.svg';
import { ReactComponent as BuildingTypeIcon } from '../img/CADConverter/buildingTypeIcon.svg';
import { dataParsing, asyncFileRead, blockParsing } from './FileParser';
import { NaverMapManager, NaverPoint, NaverLatLng, NaverPolygon } from './NaverMapManager';
import { jstsPolygontoWKT, SceneManager, tm2latlng } from './SceneManager';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, polygonBufferWithDistance, getDEM, getBuildingData, checkFileName, checkSpecialSymbolInName, getRoadLine, makeBlockBuildingData } from './DBManager';
import { MakeANewBuilding, deleteFieldFromFieldList, getFieldsArea, deleteBuildingFromBuildingList, getTotalHousehold, calculateAreaProPortion, buildingStoriesAvg, mouseOutLayerTag, mouseOverLayerTag, mouseOverHouseTag, switchLayerState, findBuilding, wkt2LatLngs, blockBuildingStoriesAvg, getBlockTotalHouseHold, calculateBlockAreaProPortion } from './CoreAndHouseController';
import { any } from 'prop-types';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ForwardArrow from '@material-ui/icons/ArrowForward';
import { Field, FieldType } from './Field';
import { BuildingType } from './BuildingType';
import { buildingData, building, resultLocation } from './resultLocationDataStruct';
import { shapeInfoData } from './ProjectDataStruct';
import { resultS3Json, base_report_file_path, base_result_file_path, housing_type_ratio } from './resultDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import AWSModule from '../AWSModule';
import App from '../App';
import { FieldUI } from './FieldUI';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { House } from './House';
import { Core } from './Core';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, ConverterBuilding, BuildingComponent } from './BuildingPart';

const uuid4 = require('uuid/v4');
const jsts = require('jsts');

interface MyPlaneProps {

}

interface MyPlaneState {
  layers: ConverterLayer[];
  handle: number;
  isCad: boolean;
  layerListHidden: boolean;
  areaListHidden: boolean;
  buildingListHidden: boolean;
  houseListHidden: boolean;
  screenWidth: number;
  screenHeight: number;
  canvasAlpha: number;
  address: string;
  fileName: string;
  saveName: string;
  showLayer: boolean;
  selectLayer: ConverterLayer | null;
  settingID: string;
  siteArea: number;
  totalGroundArea: number;
  totalBuildingArea: number;
  exclusiveAverageArea: number;
  isSettingSite: boolean;
  listType: ListType;
  houses: House[];
  selectHouse: House | null;
  buildings: BuildingTypeData[];
  userID: string,
  showModal: boolean,
  autoset: boolean,
  isCadastral: boolean,
  loadFile: boolean,
  dragCover: boolean,
  showSettingModal: boolean,
}

export class Scene extends Component<MyPlaneProps, MyPlaneState> {
  state: MyPlaneState = {
    layers: [],
    handle: 0,
    isCad: true,
    areaListHidden: false,
    layerListHidden: true,
    buildingListHidden: false,
    houseListHidden: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    canvasAlpha: 50,
    address: '',
    fileName: '선택된 파일 없음',
    selectLayer: null,
    settingID: '',
    showLayer: true,
    saveName: '',
    exclusiveAverageArea: 0,
    siteArea: 0,
    totalBuildingArea: 0,
    totalGroundArea: 0,
    isSettingSite: true,
    listType: ListType.layer,
    houses: [],
    selectHouse: null,
    buildings: [],
    userID: 'test@1011.co.kr',
    showModal: false,
    autoset: false,
    isCadastral: false,
    loadFile: false,
    dragCover: true,
    showSettingModal: false,
  };

  mount: HTMLDivElement | null = null;

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  Buildings = new Array<BuildingTypeData>();

  siteFields: Field[] = [];
  roadFields: Field[] = [];
  vacancyOutsideFields: Field[] = [];
  vacancyInsideFields: Field[] = [];

  parsingOutput: BlockParsingData = { buildings: [], fields: [] }
  block: ConverterBlock[] = [];

  naverPolygon: any;
  naverMapProj: any;
  basePosition: any;

  DEM: DEMData[] = [];

  selectingLayer = {} as selectHouse;
  mapProj: mapProjectionData = { mapOffset: new THREE.Vector2(), projection: any };
  addType = LayerType.site;
  S3BucketName = 'teneleven-platform-my-building-plan-v2';
  DBTableName = 'platform-buildit-my-building-plan-v2';

  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;

  centerOfRoadWKT = '';
  settingData = new userSettingData(App.tempStage, App.session.email);

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();
    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);
    this.calculateMapProjectionData();
    this.setState({
      userID: App.session.email,
    })
    this.animate();
    this.settingData.getDataFromDB();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate = (previousProps: Readonly<MyPlaneProps>, previousState: Readonly<MyPlaneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
      }
    }
    if (this.state.showLayer !== previousState.showLayer) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth - (!this.state.showLayer ? 235 : 0) - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122,
    });
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        this.saveBlockData();
        break;
      case 'b':
        this.parsingOutput.buildings.forEach(b => {
          console.log(makeBlockBuildingData(b));
        })
        break;
      default:
        break;
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      default:
        break;
    }
  }

  loadFilebyFile = async (file: any) => {
    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    // let layer = dataParsing(data);
    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit);

    this.polygon2DGroup.children = [];
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyInsideFields = [];
    this.vacancyOutsideFields = [];
    this.Buildings = [];
    this.deleteNaverPolygon();

    console.log(this.parsingOutput);
    this.parsingOutput.buildings.forEach(b => {
      this.polygon2DGroup.add(b.renderGroup);
    })

    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.RebuildOutputPolygon();
        p.UpdateArea();
      })
    })

    this.parsingOutput.fields.forEach(f => {
      this.polygon2DGroup.add(f.renderGroup);
    })

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);
    console.log(this.bbox);
    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
      let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }

    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      // layers: layer,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
    }, async () => {
      this.onWindowResize();
      // if (this.settingData.autoSetting) {
      //   this.autoSetPlan();
      // }
      await this.AutoAdd2Map();
    })
  }

  AutoAdd2Map = async () => {
    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields[0].getUnionJstsPolygon();

      let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0);
          //@ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
          let cPoint = sitePolygon.getCentroid();
          center.set(cPoint.getX(), cPoint.getY());

          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.road) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
      })

      this.mapManager.setNaverSitePolygon(SiteLatlngs);
      this.mapManager.setNaverRoadPolygon(RoadLatlngs);
      this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
      this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
      
      let centerInLL = tm2latlng(center);
      this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      this.parsingOutput.fields.forEach(f => {
        //@ts-ignore
        unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
      })

      if (centerRoadlatlngs.length === 0) {
        this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);

      this.setState({
        siteArea: Number(sitePolygon!.getArea().toFixed(2)),
      })
    }
  }

  saveBlockData = async () => {
    let fn = this.state.saveName === '' ? 'my_building_plan' : this.state.saveName;
    if (checkSpecialSymbolInName(fn)) {
      this.showModal('알림', ['제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.'], buttonNum.oneButton, () => console.log('special symbol'));
      return;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName) {
      this.showModal('알림', ['이미 같은 제목을 가진 나의 배치안이 존재합니다.'], buttonNum.oneButton, () => console.log('exist name'));
      return;
    }

    this.showModal('알림', ['나의 배치안을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    // let id = '0'; //uuid4();
    let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_plan_id", this.state.userID);

    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let resultLocationName = 'resultLocation.json';
    let resultJsonName = 'resultJson.json';
    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

    await this.captureImage(imageName, S3SavePath);

    this.calculateMapProjectionData();

    //shape infomation
    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];

    this.parsingOutput.fields.forEach(f => {
      let matrix = f.renderGroup.matrixWorld;
      if (f.typeName === FieldType.site) {
        f.parts.forEach(p => {
          projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.road) {
        f.parts.forEach(p => {
          roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyInside) {
        f.parts.forEach(p => {
          vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyOutside) {
        f.parts.forEach(p => {
          vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
    })

    let shapeInfo: shapeInfoData = {
      boundaryLine: polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment),
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [''],
      road: roadSiteWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      setbackLineApartment: [''],
      setbackLineMultiHouse: [''],
      setbackLineOfficetel: [''],
      setbackLineRowHouse: [''],
      skylineCircle: [''],
      skylineLine: [''],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: [],
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    // result location
    let buildingData: buildingData[] = [];
    let building_Stories_Avg = blockBuildingStoriesAvg(this.parsingOutput.buildings);// buildingStoriesAvg(this.Buildings);
    let floorAreaRatio = Number(((this.state.totalGroundArea / this.state.siteArea) * 100).toFixed(12));
    let coverAreaRatio = Number(((this.state.totalBuildingArea / this.state.siteArea) * 100).toFixed(12));
    let totalHousehold = getBlockTotalHouseHold(this.parsingOutput.buildings);
    let cost = Math.floor(this.state.siteArea * floorAreaRatio * 0.0001 * 1450167);
    let areaProportion = calculateBlockAreaProPortion(this.parsingOutput.buildings);

    let lMax = 0;
    let lMin = 10000;
    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        if (p.buildingType === 'component') {
          lMax = Math.max((p as BuildingComponent).level.length, lMax);
          lMin = Math.min((p as BuildingComponent).level.length, lMin);
        }
      })
    })

    this.parsingOutput.buildings.forEach(b => {
      buildingData.push(makeBlockBuildingData(b));
    })

    let templates: { [key: string]: building } = {};
    let buildingIndex = 0;
    buildingData.forEach(b => {
      // b.position = this.sceneManager.getPointToMap(b.position, this.mapProj);
      b.name = `building${buildingIndex}`;
      b.building.name = `building${buildingIndex}`;
      templates[b.building.name] = b.building;
      buildingIndex++;
    });

    let resultLocation: resultLocation = {
      buildingNumber: this.parsingOutput.buildings.length,
      areaProportion: areaProportion,
      buildingStoriesAvg: [building_Stories_Avg.AREA, building_Stories_Avg.HOUSE, building_Stories_Avg.NUMERICAL],
      buildingStoriesMax: lMax,
      buildingStoriesMin: lMin,
      buildings: buildingData,
      constructionCost: cost,
      coverAreaRatio: coverAreaRatio,
      exclusionRatio: 0,
      floorAreaRatio: floorAreaRatio,
      templateList: templates,
      totalHousehold: totalHousehold,
    }
    App.stage !== "prod" && console.log(resultLocation);
    saveDataToS3(JSON.stringify(resultLocation), S3SavePath, resultLocationName, 'application/json');

    //result --> dynamoDB
    let date = new Date().toISOString();
    let housingTypeRatio: housing_type_ratio[] = [];
    areaProportion.forEach(ap => {
      housingTypeRatio.push({
        area: ap.housingPlanTypeArea,
        bay: ap.numberOfBay,
        proportion: Math.floor(ap.housingPlanTypeProportion * 10000) / 100,
      })
    })

    let baseReportFilePath: base_report_file_path = {
      analysisReport: '',
      cad: '',
      constructionReport: '',
      exclusiveReport: '',
      lightReport: '',
      resImage: '',
      viewReport: '',
    }

    let baseResultFilePath: base_result_file_path = {
      light: '',
      location: '',
      view: ''
    }

    let resultJson: resultS3Json = {
      base_created_at: date,
      base_project_id: 0,
      base_report_file_path: baseReportFilePath,
      base_report_id: 0,
      base_result_status: 0,
      base_stage: 'dev',
      base_user_project_id: 0,
      base_uuid: '',
      base_result_file_path: baseResultFilePath,
      daylight_hours_avg: 0,
      daylight_hours_min: 0,
      daylight_hours_mode: 0,
      daylight_hours_proportion_less_n_hours: [100],
      loc_building_land_ratio: 0,
      loc_building_number: 0,
      loc_building_stores_avg: building_Stories_Avg,
      loc_building_stories_max: lMax,
      loc_building_stories_min: lMin,
      loc_construction_cost: cost,
      loc_exclusive_ratio_avg: 0,
      loc_floor_area_ratio: floorAreaRatio,
      loc_housing_type_ratio: housingTypeRatio,
      loc_my_building_type_ratio: [],
      loc_total_household: totalHousehold,
      view_point_avg: 0,
      view_point_skew: 0,
      view_skyarea_avg: 0,
      view_skyarea_min: 0,
      view_skyarea_mode: 0,
      view_skyarea_proportion_less_n_percent: [100],
    }
    App.stage !== "prod" && console.log(resultJson);
    saveDataToS3(JSON.stringify(resultJson), S3SavePath, resultJsonName, 'application/json');

    // let bufferSitePolygon = polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment);
    let dbItem: buildingPlanStruct = {
      stage: App.stage,
      global_id: globalID,
      user_id: privateID,
      email: this.state.userID,
      name: fn,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      resultJson: `s3://${S3SavePath}/${resultJsonName}`,
      resultLocation: `s3://${S3SavePath}/${resultLocationName}`,
      img_path: `s3://${S3SavePath}/${imageName}`,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: 0,//getFieldsArea(this.roadFields),
      road_site: roadSiteWKT,
      vacancy_outside_area: 0,//getFieldsArea(this.vacancyOutsideFields),
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: 0,//getFieldsArea(this.vacancyInsideFields),
      vacancy_inside: vacancyInsideWKT,
      building_number: this.parsingOutput.buildings.length,// Buildings.length,
      total_building_area: this.state.totalBuildingArea,
      total_floor_area: this.state.totalGroundArea,
      average_exclusive_area: this.state.exclusiveAverageArea,
      cover_area_ratio: coverAreaRatio,
      floor_area_ratio: floorAreaRatio,
      created_at: date,
      modified_at: date,
      deleted: false
    };
    App.stage !== "prod" && console.log(dbItem);
    saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal('알림', ['나의 배치안을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));

  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  addNewField = (type: FieldType) => {
    let uuid = uuid4();
    switch (type) {
      case FieldType.site:
        if (this.siteFields.length > 0) {
          this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
          break;
        }
        else {
          this.siteFields.push(new Field(`대지영역 ${this.siteFields.length}`, FieldType.site))
        }
        break;
      case FieldType.road:
        this.roadFields.push(new Field(`도로영역 ${this.roadFields.length}`, FieldType.road))
        break;
      case FieldType.vacancyOutside:
        this.vacancyOutsideFields.push(new Field(`공지영역 ${this.vacancyOutsideFields.length}`, FieldType.vacancyOutside))
        break;
      case FieldType.vacancyInside:
        this.vacancyInsideFields.push(new Field(`특수영역 ${this.vacancyInsideFields.length}`, FieldType.vacancyOutside))
        break;
      default:
        break;
    }

    this.setState({
      settingID: uuid,
      showLayer: false,
    })

    this.recalculateArea();
  }

  addBuildings = (name: string = `동평면 ${this.Buildings.length + 1}`, hideList = false) => {
    this.Buildings.push(MakeANewBuilding(name, hideList));
    this.setState({ buildings: this.Buildings });
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  getFrustumSizeWithMapSize = () => {
    let rect = this.mount!.getBoundingClientRect();
    let p1 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return this.mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    this.sceneManager.CameraFrustumResize(this.getFrustumSizeWithMapSize(), aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);

    this.setState({
      isCadastral: isCadastral
    })
  }

  recalculateArea = () => {
    let totalBuildingArea = 0;
    let totalGroundArea = 0;
    let exclusiveAverageArea = 0;

    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        totalBuildingArea += c.area;

        c.houses.forEach(h => {
          totalBuildingArea += h.exclusiveArea + h.serviceArea;
        })
      })
    })

    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        let l = 1;
        let p = 100;
        c.houses.forEach(h => {
          totalGroundArea += h.exclusiveArea * (h.level.length - h.piloti);
          l = Math.max(l, h.level.length);
          p = Math.min(p, h.piloti);
        })
        totalGroundArea += c.area * (l - p);

        if (p > 0)
          totalGroundArea += c.area;
      })
    })

    let count = 0;
    this.Buildings.forEach(b => {
      b.houses.forEach(h => {
        exclusiveAverageArea += h.exclusiveArea;
      })
      count += b.houses.length;
    })

    if (count !== 0) { exclusiveAverageArea /= count; }

    this.setState({
      siteArea: getFieldsArea(this.siteFields),
      totalBuildingArea: totalBuildingArea,
      totalGroundArea: totalGroundArea,
      exclusiveAverageArea: Number(exclusiveAverageArea.toFixed(2)),
    })
  }

  captureImage = async (imageName: string, savePath: string) => {
    // 선택된것만 켜기
    // await new Promise((resolve, reject) => {
    //   this.state.layers.forEach(l => {
    //     let visible = false;
    //     if (l.selected)
    //       visible = true;
    //     l.polygons.forEach(p => {
    //       p.lineMesh.visible = visible;
    //     })
    //   })
    //   resolve(0);
    // })

    let captureBbox = new THREE.Box3();
    this.parsingOutput.buildings.forEach(b => {
      captureBbox.expandByObject(b.renderGroup);
    })
    this.parsingOutput.fields.forEach(f => {
      captureBbox.expandByObject(f.renderGroup);
    })
    // this.siteFields.forEach(l => {
    //   l.getLayer()!.polygons.forEach(p => {
    //     if (p.selected) {
    //       p.vertices.forEach(v => {
    //         captureBbox.expandByPoint(v);
    //       })
    //     }
    //   })
    // })

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    App.stage !== "prod" && console.log('save image');
    saveDataToS3(lImgBuf, savePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, savePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, savePath, 'img_small.png', 'image/png');

    // 전체 켜기
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  getBuildingHight = async (build: building, pos: THREE.Vector3) => {
    let lambda = await new AWSModule("LAMBDA").connect();

    let points: THREE.Vector3[] = [];
    build.outline.forEach(o => {
      o.node.data.forEach(d => {
        points.push(pos.clone().sub(new THREE.Vector3(d.x, d.y, 0)));
      })
    })

    let tmPoints = this.sceneManager.getPointArrayToMap(points, this.mapProj);

    let heights: number[] = [];
    tmPoints.forEach(async p => {
      await lambda.Lambda!.invoke({
        FunctionName: "buildit-public-platform-GetDemHeight-v20200410",
        Payload: JSON.stringify({
          x: p.x,
          y: p.y,
        })
      }).promise().then(r => {
        heights.push(JSON.parse(r.Payload as string).body.value * 0.1);
      });
    });

    let maxHeight = 0;
    heights.forEach(h => {
      maxHeight = maxHeight < h ? h : maxHeight;
    });
    return maxHeight;
  }

  calculateMapProjectionData() {
    // this.mapProj.sceneOffset = this.mount!.getBoundingClientRect();
    this.mapProj.projection = this.mapManager.getProjection();
    if (this.basePosition) {
      let origin = this.sceneManager.getLatLonPosition(this.siteFields[0].getLayer()!.polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj);
      this.mapProj.mapOffset = new THREE.Vector2(this.basePosition.x, this.basePosition.y).sub(new THREE.Vector2(origin.x, origin.y));
    }
    else {
      this.mapProj.mapOffset = new THREE.Vector2(0, 0);
    }
  }

  checkAllBuilding = (names: string[]) => {
    this.Buildings.forEach(b => {
      let coreHouses = 0;
      b.cores.forEach(c => {
        coreHouses += c.houses.length;
        if (!c.CheckCompleteness()) {
          names.push(b.name + '_' + c.name);
        }
      })

      if (coreHouses > b.houses.length) {
        names.push(b.name);
      }

      // b.houses.forEach(h => {
      //   if (!h.checkCompleteness()) {
      //     names.push(b.name + '_' + h.name);
      //   }
      // })
    });

    if (names.length === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  saveToAWS = async () => {
    let names: string[] = [];
    // let finished = this.checkAllBuilding(names);
    // if (!finished) {
    //   this.showModal('알림', `${names.join(', ')}에 잘못된 입력값이 존재합니다.`, buttonNum.oneButton, () => console.log('not complate'));
    //   return;
    // }

    if (!this.mapProj.projection || !this.basePosition) {
      this.showModal('알림', ['사업영역을 맵에 표시 해 주세요.'], buttonNum.oneButton, () => console.log('add site to map'));
      return;
    }

    if (this.roadFields.length < 1 || getFieldsArea(this.roadFields) <= 0) {
      this.showModal('알림', ['최소 하나의 도로영역이 선택되어야 하며, 면적은 0㎡ 보다 커야합니다.'], buttonNum.oneButton, () => console.log('no road polygon'));
      return;
    }

    let fn = this.state.saveName === '' ? 'my_building_plan' : this.state.saveName;
    if (checkSpecialSymbolInName(fn)) {
      this.showModal('알림', ['제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.'], buttonNum.oneButton, () => console.log('special symbol'));
      return;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName) {
      this.showModal('알림', ['이미 같은 제목을 가진 나의 배치안이 존재합니다.'], buttonNum.oneButton, () => console.log('exist name'));
      return;
    }

    this.showModal('알림', ['나의 배치안을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    // let id = '0'; //uuid4();
    let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_plan_id", this.state.userID);

    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let resultLocationName = 'resultLocation.json';
    let resultJsonName = 'resultJson.json';
    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

    await this.captureImage(imageName, S3SavePath);

    this.calculateMapProjectionData();

    //shape infomation
    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];

    this.siteFields.forEach(f => {
      projectSiteWKT = projectSiteWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
    });

    this.roadFields.forEach(f => {
      roadSiteWKT = roadSiteWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
    })

    this.vacancyInsideFields.forEach(f => {
      vacancyInsideWKT = vacancyInsideWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
    })

    this.vacancyOutsideFields.forEach(f => {
      vacancyOutsideWKT = vacancyOutsideWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
    })

    let shapeInfo: shapeInfoData = {
      boundaryLine: polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment),
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [''],
      road: roadSiteWKT,
      centerOfRoad: [''],
      setbackLineApartment: [''],
      setbackLineMultiHouse: [''],
      setbackLineOfficetel: [''],
      setbackLineRowHouse: [''],
      skylineCircle: [''],
      skylineLine: [''],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: [],
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    // result location
    let buildingData: buildingData[] = [];
    let building_Stories_Avg = buildingStoriesAvg(this.Buildings);
    let floorAreaRatio = Number(((this.state.totalGroundArea / this.state.siteArea) * 100).toFixed(12));
    let coverAreaRatio = Number(((this.state.totalBuildingArea / this.state.siteArea) * 100).toFixed(12));
    let totalHousehold = getTotalHousehold(this.Buildings);
    let cost = Math.floor(this.state.siteArea * floorAreaRatio * 0.0001 * 1450167);
    let areaProportion = calculateAreaProPortion(this.Buildings);

    let lMax = 0;
    let lMin = 10000;
    this.Buildings.forEach(b => {
      b.cores.forEach(c => {
        c.houses.forEach(h => {
          lMax = Math.max(h.level.length, lMax);
          lMin = Math.min(h.level.length, lMin);
        })
      })
    });

    this.Buildings.forEach(b => {
      buildingData.push(getBuildingData(b.cores));
    });

    // let templates: Map<string, building> = new Map();
    let templates: { [key: string]: building } = {};
    let buildingIndex = 0;
    buildingData.forEach(b => {
      let bHeight = this.getBuildingHight(b.building, b.position);
      b.position = this.sceneManager.getPointToMap(b.position, this.mapProj);
      // bHeight.then(v => {
      //   b.position.z = v.valueOf()
      // })
      b.name = `building${buildingIndex}`;
      b.building.name = `building${buildingIndex}`;
      templates[b.building.name] = b.building;
      buildingIndex++;
    });

    let resultLocation: resultLocation = {
      buildingNumber: this.Buildings.length,
      areaProportion: areaProportion,
      buildingStoriesAvg: [building_Stories_Avg.AREA, building_Stories_Avg.HOUSE, building_Stories_Avg.NUMERICAL],
      buildingStoriesMax: lMax,
      buildingStoriesMin: lMin,
      buildings: buildingData,
      constructionCost: cost,
      coverAreaRatio: coverAreaRatio,
      exclusionRatio: 0,
      floorAreaRatio: floorAreaRatio,
      templateList: templates,
      totalHousehold: totalHousehold,
    }
    App.stage !== "prod" && console.log(resultLocation);
    saveDataToS3(JSON.stringify(resultLocation), S3SavePath, resultLocationName, 'application/json');

    //result --> dynamoDB
    let date = new Date().toISOString();
    let housingTypeRatio: housing_type_ratio[] = [];
    areaProportion.forEach(ap => {
      housingTypeRatio.push({
        area: ap.housingPlanTypeArea,
        bay: ap.numberOfBay,
        proportion: Math.floor(ap.housingPlanTypeProportion * 10000) / 100,
      })
    })

    let baseReportFilePath: base_report_file_path = {
      analysisReport: '',
      cad: '',
      constructionReport: '',
      exclusiveReport: '',
      lightReport: '',
      resImage: '',
      viewReport: '',
    }

    let baseResultFilePath: base_result_file_path = {
      light: '',
      location: '',
      view: ''
    }

    let resultJson: resultS3Json = {
      base_created_at: date,
      base_project_id: 0,
      base_report_file_path: baseReportFilePath,
      base_report_id: 0,
      base_result_status: 0,
      base_stage: 'dev',
      base_user_project_id: 0,
      base_uuid: '',
      base_result_file_path: baseResultFilePath,
      daylight_hours_avg: 0,
      daylight_hours_min: 0,
      daylight_hours_mode: 0,
      daylight_hours_proportion_less_n_hours: [100],
      loc_building_land_ratio: 0,
      loc_building_number: 0,
      loc_building_stores_avg: building_Stories_Avg,
      loc_building_stories_max: lMax,
      loc_building_stories_min: lMin,
      loc_construction_cost: cost,
      loc_exclusive_ratio_avg: 0,
      loc_floor_area_ratio: floorAreaRatio,
      loc_housing_type_ratio: housingTypeRatio,
      loc_my_building_type_ratio: [],
      loc_total_household: totalHousehold,
      view_point_avg: 0,
      view_point_skew: 0,
      view_skyarea_avg: 0,
      view_skyarea_min: 0,
      view_skyarea_mode: 0,
      view_skyarea_proportion_less_n_percent: [100],
    }
    App.stage !== "prod" && console.log(resultJson);
    saveDataToS3(JSON.stringify(resultJson), S3SavePath, resultJsonName, 'application/json');

    // let bufferSitePolygon = polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment);
    let dbItem: buildingPlanStruct = {
      stage: App.stage,
      global_id: globalID,
      user_id: privateID,
      email: this.state.userID,
      name: fn,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      resultJson: `s3://${S3SavePath}/${resultJsonName}`,
      resultLocation: `s3://${S3SavePath}/${resultLocationName}`,
      img_path: `s3://${S3SavePath}/${imageName}`,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: getFieldsArea(this.roadFields),
      road_site: roadSiteWKT,
      vacancy_outside_area: getFieldsArea(this.vacancyOutsideFields),
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: getFieldsArea(this.vacancyInsideFields),
      vacancy_inside: vacancyInsideWKT,
      building_number: this.Buildings.length,
      total_building_area: this.state.totalBuildingArea,
      total_floor_area: this.state.totalGroundArea,
      average_exclusive_area: this.state.exclusiveAverageArea,
      cover_area_ratio: coverAreaRatio,
      floor_area_ratio: floorAreaRatio,
      created_at: date,
      modified_at: date,
      deleted: false
    };
    App.stage !== "prod" && console.log(dbItem);
    saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal('알림', ['나의 배치안을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
  }

  addSiteAreaToMap = async () => {
    if (!this.mapManager.isCreate() || this.siteFields.length === 0 || !this.siteFields[0].getLayer())
      return;

    this.calculateMapProjectionData();

    this.basePosition = this.sceneManager.getLatLonPosition(this.siteFields[0].getLayer()!.polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj);

    let latlngs: any[] = [];
    this.siteFields.forEach(f => {
      f.getLayer()!.polygons.forEach(p => {
        let polygon: any[] = [];
        if (p.selected || (p.motherPolygon && p.motherPolygon.selected)) {
          p.vertices.forEach(v => {
            polygon.push(this.sceneManager.getLatLonPosition(v.clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj));
          })
        }
        latlngs.push(polygon);
      });
    });

    let center = new THREE.Vector2(0, 0);
    let count = 0;
    latlngs.forEach(ll => {
      count += ll.length;
      //@ts-ignore
      ll.forEach(l => {
        center.add(new THREE.Vector2(l.x, l.y));
      });
    });
    center.divideScalar(count);

    let centerTM = this.sceneManager.getPointToMap(new THREE.Vector3(center.x, center.y, 0), this.mapProj);

    await getDEM(centerTM.x, centerTM.y).then(v => {
      this.DEM = v;
    });
    App.stage !== "prod" && console.log(this.naverPolygon);
    if (this.naverPolygon) {
      this.naverPolygon.setPaths(latlngs);
    }
    else {
      this.naverPolygon = NaverPolygon({
        map: this.mapManager.getMap(),
        paths: latlngs,
        fillColor: '#ff0000',
        fillOpacity: 0.3,
        strokeColor: '#ff0000',
        strokeOpacity: 0.6,
        strokeWeight: 3
      });
    }

    let wkt = this.sceneManager.getFieldWKTFile(this.siteFields[0], this.mapProj, this.polygon2DGroup.matrix)
    const reader = new jsts.io.WKTReader();
    let area = wkt.map(r => reader.read(r).getArea()).reduce((a, b) => a + b, 0)
    if (!this.makedAreaBias) {
      this.areaBias = 1 - (1 - this.state.siteArea / area) / 2;
      this.makedAreaBias = true;
    }
    this.resizeCanvasResolution();
  }

  removeFieldFromFieldList = (field: Field) => {
    let i = this.siteFields.indexOf(field);
    if (i > -1) {
      this.deleteNaverPolygon();
    }

    deleteFieldFromFieldList(this.siteFields, field);
    deleteFieldFromFieldList(this.roadFields, field);
    deleteFieldFromFieldList(this.vacancyOutsideFields, field);
    deleteFieldFromFieldList(this.vacancyInsideFields, field);

    this.recalculateArea();
  }

  removeBuildingFromeBuildingList = (building: BuildingTypeData) => {
    deleteBuildingFromBuildingList(this.Buildings, building);
    this.recalculateArea();
  }

  showLayerList = (id: string) => {
    this.setState({
      showLayer: false,
      listType: ListType.layer,
      settingID: id,
    })
  }

  showHouseList = (id: string, houses: House[]) => {
    this.setState({
      showLayer: false,
      houses: houses,
      listType: ListType.house,
      settingID: id,
    })
  }

  setSelectLayer = (click: ConverterLayer) => {
    if (click.selected) {
      this.showModal('알림', ['이미 선택되어 있는 레이어입니다.'], buttonNum.oneButton, () => console.log('selected layer'));
      return;
    }

    if (click.polygons.length > 1 && this.siteFields.length > 0 && this.siteFields[0].id === this.state.settingID) {
      this.showModal('알림', ['여러 개의 대지영역이 동시에 입력될 수 없습니다. 가장 큰 면적의 대지영역이 자동으로 선택됩니다.'], buttonNum.oneButton, () => console.log('add multipolygon to site layer'));
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateArea);
    }
    else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateArea));
    }
  }

  setSelectHouse = (click: House) => {
    if (!this.state.selectHouse || click !== this.state.selectHouse) {
      this.setState({ selectHouse: click });
    }
    else {
      this.setState({ selectHouse: null }, () => this.setState({ selectHouse: click }));
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    });
  }

  deleteNaverPolygon = () => {
    if (this.naverPolygon) {
      this.naverPolygon.setMap(null);
      this.naverPolygon = undefined;
    }
  }

  clearAllSettingData = () => {
    for (let i = 0; i < this.Buildings.length;) {
      this.removeBuildingFromeBuildingList(this.Buildings[i]);
    }

    for (let i = 0; i < this.siteFields.length;) {
      deleteFieldFromFieldList(this.siteFields, this.siteFields[i]);
    }
    this.deleteNaverPolygon();

    for (let i = 0; i < this.roadFields.length;) {
      deleteFieldFromFieldList(this.roadFields, this.roadFields[i]);
    }

    for (let i = 0; i < this.vacancyInsideFields.length;) {
      deleteFieldFromFieldList(this.vacancyInsideFields, this.vacancyInsideFields[i]);
    }

    for (let i = 0; i < this.vacancyOutsideFields.length;) {
      deleteFieldFromFieldList(this.vacancyOutsideFields, this.vacancyOutsideFields[i]);
    }
  }

  clearSettingData = () => {
    for (let i = 0; i < this.Buildings.length; i++)
      deleteBuildingFromBuildingList(this.Buildings, this.Buildings[i]);

    for (let i = 0; i < this.siteFields.length; i++)
      deleteFieldFromFieldList(this.siteFields, this.siteFields[i]);

    for (let i = 0; i < this.roadFields.length; i++)
      deleteFieldFromFieldList(this.roadFields, this.roadFields[i]);

    for (let i = 0; i < this.vacancyInsideFields.length; i++)
      deleteFieldFromFieldList(this.vacancyInsideFields, this.vacancyInsideFields[i]);

    for (let i = 0; i < this.vacancyOutsideFields.length; i++)
      deleteFieldFromFieldList(this.vacancyOutsideFields, this.vacancyOutsideFields[i]);

    this.recalculateArea();
  }

  render = () => {
    let bodyLayout = <div />

    if (this.state.isSettingSite) {
      bodyLayout = <div className='bodyLayout lbrBorder'>
        <div className='list'>{this.siteFields.map(f =>
          <FieldUI
            key={f.id}
            field={f}
            showLayerList={this.showLayerList}
            selectLayer={this.state.selectLayer}
            settingID={this.state.settingID}
            removeField={this.removeFieldFromFieldList}
            valueChanged={this.recalculateArea}
            showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
          />)}
        </div>
        <Button onClick={() => this.addNewField(FieldType.site)}>+ 대지영역 추가</Button>
        <div className='list'>{this.roadFields.map(f =>
          <FieldUI
            key={f.id}
            field={f}
            showLayerList={this.showLayerList}
            selectLayer={this.state.selectLayer}
            settingID={this.state.settingID}
            removeField={this.removeFieldFromFieldList}
            valueChanged={this.recalculateArea}
            showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
          />)}
        </div>
        <Button onClick={() => this.addNewField(FieldType.road)}>+ 인접도로 추가</Button>
        <div className='list'>{this.vacancyOutsideFields.map(f =>
          <FieldUI
            key={f.id}
            field={f}
            showLayerList={this.showLayerList}
            selectLayer={this.state.selectLayer}
            settingID={this.state.settingID}
            removeField={this.removeFieldFromFieldList}
            valueChanged={this.recalculateArea}
            showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
          />)}
        </div>
        <Button onClick={() => this.addNewField(FieldType.vacancyOutside)}>+ 공지영역 추가</Button>
        <div className='list'>{this.vacancyInsideFields.map(f =>
          <FieldUI
            key={f.id}
            field={f}
            showLayerList={this.showLayerList}
            selectLayer={this.state.selectLayer}
            settingID={this.state.settingID}
            removeField={this.removeFieldFromFieldList}
            valueChanged={this.recalculateArea}
            showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
          />)}
        </div>
        <Button onClick={() => this.addNewField(FieldType.vacancyInside)}>+ 특수영역 추가</Button>
      </div>
    }
    else {
      bodyLayout = <div className='bodyLayout lbrBorder'>
        {this.Buildings.map(b =>
          <BuildingType
            key={b.id}
            building={b}
            recalculate={this.recalculateArea}
            selectLayer={this.state.selectLayer}
            selectHouse={this.state.selectHouse}
            showLayerList={this.showLayerList}
            settingID={this.state.settingID}
            deleteBuilding={this.removeBuildingFromeBuildingList}
            showHouseList={this.showHouseList}
          />
        )}
        <Button className={'addBuilding'} onClick={() => this.addBuildings()}>+ 동평면 추가</Button>
        {/* <Button className={'addBuilding'} onClick={() => this.autoSetPlan()}>자동 추가</Button> */}
      </div>
    }
    return (
      <React.Fragment>
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.myPlane}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={this.clearSettingData}
          errorSize={0}
          warningSize={0}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => this.showModal('나의 배치안 저장', ['나의 배치안을 저장하시겠습니까?'], buttonNum.twoButton, this.saveBlockData)}
          showModal={this.showModal}
        />
        <div className='MainBody'>
          <div className='information'>
            <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.state.siteArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>동타입 수</div><div className='inforValue'>{this.Buildings.length}</div></div>
            <div className='info'><div className='infoLabel'>건축면적</div><div className='inforValue'>{Math.round(this.state.totalBuildingArea)}㎡</div></div>
            <div className='info'><div className='infoLabel'>바닥면적</div><div className='inforValue'>{this.state.totalGroundArea.toFixed(2)}㎡</div></div>
            <div className='info'><div className='infoLabel'>평균 전용면적</div><div className='inforValue'>{this.state.exclusiveAverageArea}㎡</div></div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
              <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showLayer: !this.state.showLayer })}>레이어 보기</span>
            </div>
          </div>
          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.myPlane}
                  showSample={true}
                />
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className='searchDiv' hidden={this.state.isCad}>
                    <input
                      type='text'
                      className='addressSearch'
                      placeholder='주소 검색'
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      onKeyUp={e => this.textInputKeyUp(e)}
                    />
                    <SearchIcon className='icon' onClick={() => this.mapManager.searchAddressToCoordinate(this.state.address)} />
                  </div>
                  <div className='rightButtons'>
                    <div className='switchTabs'>
                      <Button className={`switchButton ${this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(true)}>CAD</Button>
                      <Button className={`switchButton ${!this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(false)}>MAP</Button>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && 'hidden'}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                    </div>
                    <input
                      className={`slider ${this.state.isCad && 'hidden'}`}
                      type='range'
                      min='0'
                      max='100'
                      value={this.state.canvasAlpha}
                      onChange={(e) => this.setState({ canvasAlpha: Number(e.target.value) })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div className='functionalLayout' >
                <div className='fileNameLayout lbrBorder'>
                  <div className='titleText'>나의 배치안 제목</div>
                  <input type='text' value={this.state.saveName} onChange={e => this.setState({ saveName: e.target.value })} placeholder='나의 배치안 제목 입력'></input>
                </div>
                <div className='switchLayout'>
                  <div className='switchTabs'>
                    <Button className={`switchButton ${this.state.isSettingSite && 'enable'}`} onClick={e => this.setState({ isSettingSite: true })}><MySite className={`icon ${this.state.isSettingSite && 'enable'}`} /> 사업영역 설정</Button>
                    <Button className={`switchButton ${!this.state.isSettingSite && 'enable'}`} onClick={e => this.setState({ isSettingSite: false })}><BuildingTypeIcon className={`icon ${!this.state.isSettingSite && 'enable'}`} />동평면 설정</Button>
                  </div>
                </div>
                {bodyLayout}
                <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.addSiteAreaToMap()}>사업영역 맵 표시</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 배치안 저장', ['나의 배치안을 저장하시겠습니까?'], buttonNum.twoButton, this.saveBlockData)}>나의 배치안 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div>
              </div>
              <div className='layerLayout' hidden={this.state.showLayer}>
                <div className='arrowDiv'><ForwardArrow className='forwardArrow' onClick={e => this.setState({ showLayer: true })} /></div>
                <div className='layerList' onMouseLeave={() => mouseOutLayerTag(this.state.layers)}>
                  {(this.state.listType === ListType.layer &&
                    <div>
                      {this.state.layers!.map(l =>
                        <Button key={l.name} className={`${l.selected && 'selected'}`} onMouseOver={() => mouseOverLayerTag(this.state.layers, l)} onClick={() => this.setSelectLayer(l)}>{`${l.name}`}</Button>
                      )}
                    </div>) ||
                    <div>
                      {this.state.houses.map(h =>
                        <Button key={h.name} className={`${h.selected && 'selected'}`} onMouseOver={(e) => mouseOverHouseTag(this.state.layers, h)} onClick={() => this.setSelectHouse(h)}>{`${h.name}`}</Button>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}