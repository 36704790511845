import { ProjectJS, BuildingShapeTypeJS, HousingPlanTypeJS } from "@teneleven/protocols-ts-web/lib/project";
import { field_info } from "@teneleven/protocols-ts-web";
import { Project, ProjectBuildingShapeType, ProjectHousingPlanType, ProjectUseDistrict, ProjectUseDistrictEnum, DesignStageEnum, BuildingTypeEnum, BuildingStoriesAvgTypeEnum } from "./model/Project";
import { default as _ } from "lodash";

export type ConstraintFailure = "REQUIRED" | "MIN" | "MAX" | "LENGTH" | "INTEGER" | "TYPE";

export interface ConstraintErrors {
  errs: Partial<{ [k in keyof Project]: ConstraintError }>;
}

export interface ConstraintError {
  configType?: "SITE" | "DETAIL";
  subDetailType?: "BASIC" | "VALUE" | "BUILDING" | "HOUSING";
  field: string;
  failed: ConstraintFailure;
  msg: string;
}

export interface Constraint {
  // required: boolean;
  required: boolean | ((p?: Project) => boolean);
  min?: number | ((p: Project) => number);
  max?: number | ((p: Project) => number);
  length?: number;
  isInteger?: boolean;
  options?: string[];
  stage?: number;
  precision?: number;
  configType?: "SITE" | "DETAIL";
  subDetailType?: "BASIC" | "VALUE" | "BUILDING" | "HOUSING";
  arrConstraint?: (arr: any[], p?: Project) => { result: boolean; msg?: string };
  constraint?: (value: any, p: Project) => { result: boolean; msg?: string };
}

export const availableHousingData = {
  FOLDED_CORRIDOR_TYPE: [15, 20, 25, 30, 35, 40, 45],
  FOLDED_NON_CORRIDOR_TYPE: [49, 59, 74, 84, 99, 105, 115, 120, 125, 135, 149],
  LINEAR_CORRIDOR_TYPE: [15, 20, 25, 30, 35, 40, 45],
  LINEAR_NON_CORRIDOR_TYPE: [49, 59, 74, 84, 99, 105, 115, 120, 125, 135, 149],
  T_TOWER_TYPE: [49, 59, 74, 84, 99, 105, 115, 120, 125, 135, 149],
  Y_TOWER_TYPE: [49, 59, 74, 84, 99, 105, 115, 120, 125, 135, 149],
};

export const ProjectConstraints: Partial<{ [k in keyof Project]: Constraint }> = {
  project_name: {
    required: true,
    length: 255,
    stage: 0,
  },
  project_address: {
    required: true,
    length: 100,
    configType: "DETAIL",
    subDetailType: "BASIC",
    stage: 0,
  },
  project_use_district: {
    required: true,
    options: Object.keys(ProjectUseDistrictEnum),
    configType: "DETAIL",
    subDetailType: "BASIC",
    stage: 0,
  },
  building_type: {
    required: true,
    options: Object.keys(BuildingTypeEnum),
    configType: "DETAIL",
    subDetailType: "BASIC",
    constraint: (value: any, p?: Project) => {
      if (["DESIGNER", "SITE_PLAN"].includes(p!.project_type!)) {
        if (value === "아파트") {
          return { result: true };
        } else {
          return { result: false, msg: "디자이너 프로젝트는 아파트만 가능합니다" };
        }
      } else {
        return { result: true };
      }
    },
    stage: 0,
  },
  project_explanation: {
    required: false,
    length: 10000,
    stage: 0,
  },
  ordering_companay: {
    required: false,
    length: 100,
    stage: 0,
  },
  ordering_manager: {
    required: false,
    length: 100,
    stage: 0,
  },
  drafter: {
    required: false,
    length: 100,
    stage: 0,
  },
  drafter_contact_number: {
    required: false,
    length: 100,
    stage: 0,
  },
  design_stage: {
    required: true,
    options: Object.keys(DesignStageEnum),
    stage: 0,
  },
  design_office: {
    required: false,
    length: 100,
    stage: 0,
  },
  project_site: {
    required: true,
    arrConstraint: (arr: string[]) => {
      if (arr.length > 0) {
        return { result: true };
      } else {
        return { result: false, msg: "대지영역 설정이 필요합니다" };
      }
    },
    configType: "SITE",
    stage: 1,
  },
  project_site_center: {
    required: true,
    configType: "SITE",
    stage: 1,
  },
  road_value: {
    required: (p?: Project) => {
      if (p!.project_site_type! === "IMPORT") {
        return false;
      } else {
        return p!.project_site_type! === "DRAW" || !p!.auto_road!;
      }
    },
    arrConstraint: (arr: Array<Array<number>>, p?: Project) => {
      switch (p!.project_site_type!) {
        case "SELECT":
          // if (!p!.auto_road!) {
          //   if (arr.map(r => r.reduce((a, b) => a + b)))
          //   if (arr.reduce((a, b) => a + b) > 0) {
          //     return { result: true };
          //   } else {
          //     return { result: false, msg: "최소 한 면의 도로폭 값이 필요합니다" };
          //   }
          // } else {
          //   return { result: true };
          // }
          if (!p!.auto_road!) {
            for (let i = 0; i < arr.length; i++) {
              let sum = 0;
              for (let j = 0; j < arr[i].length; j++) {
                if (arr[i][j] > 200) {
                  return { result: false, msg: "유효 범위는 0 ~ 200m 입니다" };
                }
                sum += arr[i][j];
              }
              if (sum === 0) {
                return { result: false, msg: "최소 한 면의 도로폭 값이 필요합니다" };
              }
            }
          }
          return { result: true };
        case "IMPORT":
          return { result: true };
        case "DRAW":
          if (!p!.auto_road!) {
            for (let i = 0; i < arr.length; i++) {
              let sum = 0;
              for (let j = 0; j < arr[i].length; j++) {
                if (arr[i][j] > 200) {
                  return { result: false, msg: "유효 범위는 0 ~ 200m 입니다" };
                }
                sum += arr[i][j];
              }
              if (sum === 0) {
                return { result: false, msg: "최소 한 면의 도로폭 값이 필요합니다" };
              }
            }
          }
          return { result: true };
      }
    },
    configType: "SITE",
    stage: 1,
  },
  road_site: {
    required: (p?: Project) => {
      return p!.project_site_type! === "DRAW" || !p!.auto_road!;
      // return !(p!.building_type !== "아파트" || ["DESIGNER", "SITE_PLAN"].includes(p!.project_type!))
    },
    // arrConstraint: (arr: number[], p?: Project) => {
    //   return { result: true }
    // },
    configType: "SITE",
    stage: 1,
  },
  vacancy_outside: {
    required: false,
    stage: 1,
  },
  vacancy_inside: {
    required: false,
    stage: 1,
  },
  skyline_circle: {
    required: false,
    stage: 1,
  },
  skyline_line: {
    required: false,
    stage: 1,
  },
  project_site_area: {
    required: true,
    configType: "SITE",
    stage: 1,
  },
  building_land_ratio: {
    // required: true,
    required: (p?: Project) => {
      return !["DESIGNER", "SITE_PLAN"].includes(p!.project_type!);
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 1,
    max: 90,
    stage: 2,
  },
  floor_area_ratio: {
    // required: true,
    required: (p?: Project) => {
      return !["DESIGNER", "SITE_PLAN"].includes(p!.project_type!);
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 10,
    max: 2000,
    stage: 2,
  },
  building_stories_avg: {
    // required: true,
    required: (p?: Project) => {
      return p!.project_type === "AI" && p!.building_type === "아파트";
    },
    constraint: (value: any, p?: Project) => {
      if (p!.building_type! === "아파트") {
        if (p!.building_stories_min! <= value && value <= p!.building_stories_max!) {
          return { result: true };
        } else {
          return { result: false, msg: "최저<=평균<=최대를 확인해주세요" };
        }
      } else {
        return { result: true };
      }
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 1,
    max: (p: Project) => {
      switch (p.building_type!) {
        case "아파트":
          return 99;
        case "다세대주택":
          return 6;
        // case "도시형생활주택 - 다세대주택": return 6;
        case "오피스텔":
          return 99;
        default:
          return 100;
      }
    },
    stage: 2,
  },
  building_stories_max: {
    // required: true,
    required: (p?: Project) => {
      return !["DESIGNER", "SITE_PLAN"].includes(p!.project_type!);
    },
    constraint: (value: any, p?: Project) => {
      if (p!.building_type! === "아파트") {
        if (value >= p!.building_stories_min! && value >= p!.building_stories_avg!) {
          return { result: true };
        } else {
          return { result: false, msg: "최저<=평균<=최대를 확인해주세요" };
        }
      } else {
        return { result: true };
      }
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 1,
    max: (p: Project) => {
      switch (p.building_type!) {
        case "아파트":
          return 99;
        case "다세대주택":
          return 99;
        // case "도시형생활주택 - 다세대주택": return 6;
        case "오피스텔":
          return 99;
        default:
          return 100;
      }
    },
    isInteger: true,
    stage: 2,
  },
  building_stories_min: {
    // required: true,
    required: (p?: Project) => {
      return p!.project_type === "AI" && p!.building_type === "아파트";
    },
    constraint: (value: any, p?: Project) => {
      if (p!.building_type === "아파트") {
        if (value <= p!.building_stories_max! && value <= p!.building_stories_avg!) {
          return { result: true };
        } else {
          return { result: false, msg: "최저<=평균<=최대를 확인해주세요" };
        }
      } else {
        return { result: true };
      }
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 1,
    max: (p: Project) => {
      switch (p.building_type!) {
        case "아파트":
          return 99;
        case "다세대주택":
          return 6;
        // case "도시형생활주택 - 다세대주택": return 6;
        case "오피스텔":
          return 99;
        default:
          return 100;
      }
    },
    isInteger: true,
    stage: 2,
  },
  building_stories_avg_type: {
    // required: true,
    required: (p?: Project) => {
      return !["DESIGNER", "SITE_PLAN"].includes(p!.project_type!);
    },
    options: Object.keys(BuildingStoriesAvgTypeEnum),
  },
  floor_height: {
    required: (p?: Project) => {
      return !["DESIGNER", "SITE_PLAN"].includes(p!.project_type!);
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 2.7,
    max: (p: Project) => {
      switch (p.building_type!) {
        case "아파트":
          return 6;
        case "다세대주택":
          return 3;
        // case "도시형생활주택 - 다세대주택": return 3;
        case "오피스텔":
          return 6;
        default:
          return 100;
      }
    },
    stage: 2,
  },
  reports_number: {
    required: false,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 1,
    max: 10,
    isInteger: true,
    stage: 2,
  },
  // 정북일조 9미터 이하
  setback_regulation_from_north_less_9m: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: (p: Project) => {
      if (p.setback_regulation_from_north_less_9m_type == "METER") {
        return 2;
      } else {
        return 1;
      }
    },
    stage: 2,
  },
  // 정북일조 9미터 이하 타입
  setback_regulation_from_north_less_9m_type: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    options: ["METER", "HEIGHT"],
    stage: 2,
  },
  // 정북일조 9미터 초과
  setback_regulation_from_north_more_9m: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: (p: Project) => {
      if (p.setback_regulation_from_north_more_9m_type == "METER") {
        return 2;
      } else {
        return 1;
      }
    },
    stage: 2,
  },
  // 정북일조 9미터 초과 타입
  setback_regulation_from_north_more_9m_type: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    options: ["METER", "HEIGHT"],
    stage: 2,
  },
  // 채광사선_채광창_인접대지경계선
  setback_regulation_from_site_boundary: {
    required: (p?: Project) => {
      return p!.building_type !== "다세대주택";
    },
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 1,
    stage: 2,
  },
  // 인동간격_채광창_다른건물
  distance_between_window_opaque_walls: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 1,
    stage: 2,
  },
  // 인동간격_벽면_측벽
  distance_between_side_opaque_walls: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 99,
    stage: 2,
  },
  // 인동간격_측벽_측벽
  distance_between_side_walls: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 99,
    stage: 2,
  },
  //
  setback_building_line_apartment: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_building_line_multi_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_building_line_row_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_building_line_officetel: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_apartment: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_multi_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_row_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  //
  setback_building_line_city_apartment: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_building_line_city_row_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_building_line_city_multi_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_city_apartment: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_city_row_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_city_multi_house: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  setback_site_boundary_officetel: {
    required: true,
    configType: "DETAIL",
    subDetailType: "VALUE",
    min: 0,
    max: 10,
    stage: 2,
  },
  housing_rate: {
    required: (p?: Project) => {
      switch (p!.project_type!) {
        case "AI":
          // switch(p!.building_type!) {
          //   case "아파트":
          //     if (p!.my_building_shape_type!.length > 0 || p!.building_shape_type!.length > 0) {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   default: return false;
          // }
          return true;
        default:
          return false;
      }
    },
    configType: "DETAIL",
    subDetailType: "HOUSING",
    constraint: (value: any, p?: Project) => {
      const housingPlanType = p!.housing_plan_type!.map((r) => r.proportion!);
      const myBuildingShapeType = p!.my_building_shape_type!.map((r) => r.proportion!);
      // console.log('housingPlanType', housingPlanType);
      if (housingPlanType.includes(0) || myBuildingShapeType.includes(0)) {
        return { result: false, msg: "세대의 비율을 0보다 크게 설정해주세요" };
      } else if (value !== 100) {
        if (p!.my_building_shape_type!.length > 0 || p!.housing_plan_type!.length > 0) {
          return { result: false, msg: "세대 타입의 비율을 100%로 설정 해주세요" };
        } else {
          return { result: true };
        }
      } else {
        return { result: true };
      }
    },
  },
  //
  building_shape_type: {
    required: (p?: Project) => {
      // return !(p!.building_type !== "아파트" || ["DESIGNER", "SITE_PLAN"].includes(p!.project_type!))
      let required = false;
      switch (p!.project_type!) {
        case "AI":
          return p!.building_type! === "아파트" && p!.my_building_shape_type!.length === 0;
        case "DESIGNER":
          return p!.housing_plan_type!.length > 0;
        case "SITE_PLAN":
          return p!.housing_plan_type!.length > 0;
        default:
          return false;
      }

      // if (required && p!.my_building_shape_type!.length > 0) {
      //   required = false;
      // }
      // return required;
    },
    configType: "DETAIL",
    subDetailType: "BUILDING",
    arrConstraint: (arr: ProjectBuildingShapeType[], p?: Project) => {
      if (p!.building_type! !== "아파트") {
        return { result: true };
      } else if (p!.my_building_shape_type!.length > 0) {
        return { result: true };
      } else if (arr!.length > 4) {
        return { result: false, msg: "최대 4개까지 선택할 수 있습니다" };
      } else if (arr!.length === 0) {
        return { result: false, msg: "최소 1개의 동타입이 필요합니다" };
      } else {
        return { result: true };
      }
    },
    stage: 3,
  },
  housing_plan_type: {
    required: (p?: Project) => {
      switch (p!.project_type!) {
        case "DESIGNER":
        case "SITE_PLAN":
          return p!.building_shape_type!.length > 0;
        case "AI":
          switch (p!.building_type!) {
            case "아파트":
              return p!.building_shape_type!.length > 0;
            default:
              return true;
          }
        default:
          return false;
      }
      // return required;
    },
    configType: "DETAIL",
    subDetailType: "HOUSING",
    arrConstraint: (arr: ProjectHousingPlanType[], p?: Project) => {
      // @ts-ignore
      const aList = p!.building_shape_type!.map((r) => availableHousingData[r.figures!]);
      let setable = true;
      for (let i = 0; i < aList.length; i++) {
        let isExist = false;
        for (let j = 0; j < arr.length; j++) {
          if (aList[i].includes(arr[j].area!)) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          setable = false;
          break;
        }
      }

      // @ts-ignore
      const availList: Array<number> = _.uniq(
        _.reduceRight(
          // @ts-ignore
          p!.building_shape_type!.map((r) => availableHousingData[r.figures!]),
          (a, b) => {
            return a.concat(b);
          }
        )
      ).sort((a: any, b: any) => a - b);
      const curList = arr.map((r) => r.area);

      // const mg = _.union(availList, curList);

      const max = _.max(availList) || 10000;
      const min = _.min(availList) || 0;

      const areaCondition = _.find(curList, (item: number) => item > max || item < min);

      if (!setable) {
        return { result: false, msg: "선택 동타입의 면적이 필요합니다" };
      } else if (availList.length > 0 && areaCondition) {
        return { result: false, msg: "면적 범위를 확인해 주세요" };
      } else if (arr!.length > 4) {
        return { result: false, msg: "최대 4개까지 선택할 수 있습니다" };
        // } else if (arr!.length > 1 && p!.building_type !== "아파트") {
        //   return {result: false, msg: "최대 1개까지 선택할 수 있습니다"};
      } else if (arr!.map((v) => v.area).includes(undefined)) {
        return { result: false, msg: "세대타입의 면적을 설정해 주세요" };
      } else if (["DESIGNER", "SITE_PLAN"].includes(p!.project_type!)) {
        return { result: true };
      } else {
        return { result: true };
      }
    },
    stage: 4,
  },
  daylight_hours_demanded_avg: {
    required: true,
    min: 0,
    max: 8,
    stage: 5,
  },
  daylight_hours_proportion_less_avg: {
    required: true,
    min: 0,
    max: 100,
    stage: 5,
  },
  view_type_green: {
    required: true,
    min: 0,
    max: 100,
    stage: 6,
  },
  view_type_sky: {
    required: true,
    min: 0,
    max: 100,
    stage: 6,
  },
  view_type_river: {
    required: true,
    min: 0,
    max: 100,
    stage: 6,
  },
  view_type_landmark: {
    required: true,
    min: 0,
    max: 100,
    stage: 6,
  },
  auto_setting: {
    required: true,
  },
};

function checkConstraint(p: Project, field: keyof Project) {
  const c = ProjectConstraints[field] as Constraint;

  if (p[field] instanceof Array) {
    CheckField(p[field]);
    /* (p[field] as any[]).forEach(c => {
      CheckField(c);
    }) */
  } else {
    CheckField(p[field]);
  }

  function CheckField(value: any) {
    let required = false;
    if (c.required instanceof Function) {
      required = c.required(p);
      if (required && (value === undefined || value == null || (Array.isArray(value) && value.length === 0))) {
        throw {
          configType: c.configType,
          subDetailType: c.subDetailType,
          field: field,
          failed: "REQUIRED",
          msg: "필수 입력 사항입니다",
        } as ConstraintError;
      }
    } else {
      required = c.required;
      if (c.required && (value === undefined || value == null || (Array.isArray(value) && value.length === 0))) {
        throw {
          configType: c.configType,
          subDetailType: c.subDetailType,
          field: field,
          failed: "REQUIRED",
          msg: "필수 입력 사항입니다",
        } as ConstraintError;
      }
    }

    if (required) {
      if (c.max) {
        if (c.max instanceof Function) {
          if (value > c.max(p)) {
            throw {
              configType: c.configType,
              subDetailType: c.subDetailType,
              field: field,
              failed: "MAX",
              msg: `유효 범위는 ${c.min} ~ ${c.max(p)} 입니다`,
            } as ConstraintError;
          }
        } else if (value > c.max) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "MAX",
            msg: `유효 범위는 ${c.min} ~ ${c.max} 입니다`,
          } as ConstraintError;
        }

        if (c.min) {
          if (value < c.min) {
            throw {
              field: field,
              configType: c.configType,
              subDetailType: c.subDetailType,
              failed: "MIN",
              msg: `유효 범위는 ${c.min} ~ ${c.max instanceof Function ? c.max(p) : c.max} 입니다`,
            } as ConstraintError;
          }
        }
      }

      if (c.length) {
        if (value.toString().length > c.length) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "LENGTH",
            msg: `최대 입력 가능한 길이는 ${c.length} 입니다`,
          } as ConstraintError;
        }
      }

      if (c.isInteger) {
        if (Number.isInteger(value) == false) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "INTEGER",
            msg: "정수 입력만 가능합니다",
          } as ConstraintError;
        }
      }

      if (c.options) {
        if (c.options.indexOf(value) === -1) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "TYPE",
            msg: "선택이 필요합니다",
          } as ConstraintError;
        }
      }

      if (c.arrConstraint) {
        const r = c.arrConstraint(value, p);
        if (r.result === false) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "MAX",
            msg: r.msg,
          } as ConstraintError;
        }
      }

      if (c.constraint) {
        const r = c.constraint(value, p);
        if (r.result === false) {
          throw {
            field: field,
            configType: c.configType,
            subDetailType: c.subDetailType,
            failed: "TYPE",
            msg: r.msg,
          } as ConstraintError;
        }
      }
    }
  }
}

export function projectInputValidCheckSome(p: Project, fields: (keyof Project)[]) {
  const list = fields
    .map((k) => {
      try {
        checkConstraint(p, k);
        return undefined;
      } catch (e) {
        return { [k]: e };
      }
    })
    .filter((r) => r !== undefined)
    .reduce((a, b) => Object.assign(a, b), {}) as { [k in keyof Project]: ConstraintError };

  const r: ConstraintErrors = {
    errs: list,
  };
  return r;
}

export function projectInputValidCheck(p: Project) {
  // 전체체크
  const list = Object.keys(ProjectConstraints)
    .map((k) => {
      try {
        checkConstraint(p, k as keyof Project);
        return undefined;
      } catch (e) {
        return { [k]: e };
      }
    })
    .filter((r) => r !== undefined)
    .reduce((a, b) => Object.assign(a, b), {}) as { [k in keyof Project]: ConstraintError };

  const r: ConstraintErrors = {
    errs: list,
  };

  return r;
}
