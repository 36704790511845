import { OpenInNew, SubdirectoryArrowRight } from '@material-ui/icons';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import App from './App';
import './css/CopyProjectInfo.scss';
import Tooltip from './Tooltip';

export interface CopyProjectInfoProps {
  copyProjectId: number;
  showArrowIcon: boolean;
}

export interface CopyProjectInfoState {
  copyURL: string;
}

class CopyProjectInfo extends Component<CopyProjectInfoProps, CopyProjectInfoState> {
  state: CopyProjectInfoState = {
    copyURL: '',
  }

  componentWillMount() {
    this.getCopyProjectURL(this.props.copyProjectId);
  }

  getCopyProjectURL = async (copyId: number) => {
    const es_res = await App.search({
      table: 'platform-buildit-project',
      query: `discarded:false AND deleted:false AND user_project_id:${copyId} stage:${App.tempStage}`,
      from: 0,
      size: 1,
      sort: [{ finished_at: 'desc', created_at: 'desc' }],
    });

    if (es_res.status === 200 && es_res.data.hits.hits[0]) {
      const status = es_res.data.hits.hits[0]._source.status;
      const userProjectId = es_res.data.hits.hits[0]._source.user_project_id;

      if (status === "CONFIG") {
        this.setState({ copyURL: `/project/edit?pid=${userProjectId}` });
        return;
      }
      else {
        this.setState({ copyURL: `/project/result?pid=${userProjectId}` });
        return;
      }
    }
    else {
      console.log(es_res.statusText);
    }
    return;
  }

  render() {
    return (
      <Tooltip msg="원본 프로젝트로 이동">
        <Link to={this.state.copyURL!} className="router-link" target="_blank" >
          <div className={`CopyProjectInfo ${this.props.showArrowIcon === false && 'project-form'}`}>
            {this.props.showArrowIcon && <SubdirectoryArrowRight className="arrow-icon icon" />}
            <span className="text">{`원본 프로젝트 번호: ${this.props.copyProjectId}`}</span>
            <OpenInNew className="open-icon icon" />
          </div>
        </Link>
      </Tooltip>
    );
  }
}

export default CopyProjectInfo;