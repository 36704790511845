import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ProjectListType } from "./ProjectList"
import Tabs from './Tabs';
import ProjectIcon from './ProjectIcon';
import './css/ProjectListHeader.scss';

export interface ProjectListHeaderProps {
  projectListType: ProjectListType;
  setProjectListType: (type: ProjectListType, callback?: Function) => void;
}
export interface ProjectListHeaderState {}

export default class ProjectListHeader extends Component<ProjectListHeaderProps, ProjectListHeaderState> {
  render() {
    return (
      <div className="ProjectListHeader">
        <div className="wrapper">
          <div className="content">
            <div className="header">
              <div className="breadcrumb"><Link style={{textDecoration:"none", color:"#999"}} to="/" >HOME</Link> > 프로젝트 목록</div>
            </div>
            <div className="body">
              <div className="page-title">프로젝트 목록</div>
              <div className="project-tabs">
                <Tabs className="bg-navy tabs tabs-large">
                  <Button onClick={e => this.props.setProjectListType("ALL")} 
                  disableRipple={true} className={`${this.props.projectListType==="ALL" && "active"} bg-navy tab tab-primary tab-large all`}
                  >전체</Button>
                  <Button onClick={e => this.props.setProjectListType("CONFIG")} 
                  disableRipple={true} className={`${this.props.projectListType==="CONFIG" && "active"} bg-navy tab tab-primary tab-large config`}
                  >
                    <ProjectIcon status="CONFIG" className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">설정중</div>
                  </Button>
                  <Button onClick={e => this.props.setProjectListType("WAITING")} 
                  disableRipple={true} className={`${(this.props.projectListType==="RUNNING" || this.props.projectListType === "WAITING") && "active"} bg-navy tab tab-primary tab-large running`}>
                    <ProjectIcon status="RUNNING" className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">분석중</div>
                  </Button>
                  <Button onClick={e => this.props.setProjectListType("FINISHED")} 
                  disableRipple={true} className={`${this.props.projectListType==="FINISHED" && "active"} bg-navy tab tab-primary tab-large finished`}>
                    <ProjectIcon status="FINISHED" className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">완료</div>
                  </Button>
                  <Button onClick={e => this.props.setProjectListType("FAILED")} 
                  disableRipple={true} className={`${this.props.projectListType==="FAILED" && "active"} bg-navy tab tab-primary tab-large failed`}>
                    <ProjectIcon status="FAILED" className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">확인필요</div>
                  </Button>
                  <Button onClick={e => this.props.setProjectListType("FAVORITE")} disableRipple={true} className={`${this.props.projectListType==="FAVORITE" && "active"} bg-navy tab tab-primary tab-large favorite`}>
                    <ProjectIcon favorite={"tab"} className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">즐겨찾기</div>
                  </Button>
                  <Button onClick={e => this.props.setProjectListType("DISCARDED")} disableRipple={true} className={`${this.props.projectListType==="DISCARDED" && "active"} bg-navy tab tab-primary tab-large trash`}>
                    <ProjectIcon discard={this.props.projectListType === "DISCARDED"} className="icon-wrapper" iconClassName="tab-icon"  />
                    <div className="tab-name">휴지통</div>
                  </Button>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}