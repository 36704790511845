import React, { Component, Fragment } from 'react';
import './css/ProjectValue.scss';
import Tooltip from './Tooltip';

export interface ProjectValueProps {
  className?: string;
  title: string;
  value?: number | string;
  unit?: string;
  tooltip?: string;
}
export interface ProjectValueState {
  
}
export default class ProjectValue extends Component<ProjectValueProps, ProjectValueState> {
  render() {
    const valueType = typeof this.props.value;
    return (
      <div className={`ProjectValue ${this.props.className}`}>
        <div className="wrapper">
          <div className="project-value-title">{this.props.title} 
          {
            this.props.tooltip && 
            <Tooltip msg={this.props.tooltip}>
              <div className="question">
                <img style={{ marginLeft: '6px', minWidth: "12px", height: "12px", color: "#CCCCCC", verticalAlign: 'bottom', paddingBottom: '1px'}} src="/img/question.png"></img> 
              </div>
              
            </Tooltip>
          }
          </div>
          <div className="project-value-value">
            {
              this.props.value !== undefined && valueType === "string" &&
              ((this.props.value as string).length > 0 && this.props.value || "-") ||
              (this.props.value !== undefined ? this.props.value : "-")
            }
            <span className="unit">{this.props.unit}</span>
          </div>
        </div>
      </div>
    )
  }
}