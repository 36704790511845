import DxfParser, { dxf } from '@teneleven/dxf-parser/src';
import { entity } from '@teneleven/dxf-parser/src';
import { ConverterEntity, ConverterLayer, entityType, Polygon, Unit, BlockType, ConverterInsert, ConverterUnit } from './DataTypes';
import * as THREE from '@teneleven/three';
import _ from 'lodash';
import iconv from 'iconv-lite';
import * as turf from '@turf/turf';
import { LineGeometry } from '@teneleven/three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from '@teneleven/three/examples/jsm/lines/LineMaterial';
import { Line2 } from '@teneleven/three/examples/jsm/lines/Line2';
import { BuildingGroup } from './BuildingGroup';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, ConverterBuilding, ConverterField } from './BuildingPart';
import { getS3Json } from '../Viewer/DBManager';
import { FieldType } from './Field';

const earcut = require('earcut');

export function makePolygon(vertices: THREE.Vector3[], color: THREE.Color, type: entityType, shape: boolean): Polygon {
  let vertsArea = new Array<number>();
  let turfVerts = new Array<number[]>();
  let area = 0;
  let hasCurve = false;
  let innerMesh = new THREE.Mesh();

  let box = new THREE.Box2();
  let boxSize = new THREE.Vector2();

  vertices.forEach(v => {
    vertsArea.push(v.x, v.y);
    turfVerts.push([v.x, v.y]);
    box.expandByPoint(new THREE.Vector2(v.x, v.y));
    if (v.z !== 0)
      hasCurve = true;
  });
  box.getSize(boxSize)

  if (vertices.length > 2) {
    let triangles = earcut(vertsArea);
    for (let i = 0; i < triangles.length; i += 3) {
      area += new THREE.Triangle(vertices[triangles[i]], vertices[triangles[i + 1]], vertices[triangles[i + 2]]).getArea();
    }

    const geo = new THREE.Geometry();

    geo.vertices = vertices;
    for (let i = 0; i < triangles.length; i += 3) {
      geo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));

      geo.faceVertexUvs[0].push([
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, 1),
        new THREE.Vector2(1, 0),
      ]);
    }

    geo.computeFaceNormals();

    innerMesh.geometry = geo;
    innerMesh.material = new THREE.MeshBasicMaterial({ color: color, opacity: 0.5, transparent: true });
    innerMesh.visible = false;
  }

  let meshVerts: THREE.Vector3[] = [];
  if (turf.booleanClockwise(turf.lineString(turfVerts))) {
    for (let i = vertices.length - 1; i >= 0; i--) {
      meshVerts.push(vertices[i].clone());
    }
  }
  else {
    vertices.forEach(v => {
      meshVerts.push(v.clone());
    });
  }

  let vertsGeo = new Array<number>();
  let colorGeo = new Array<number>();
  meshVerts.forEach(v => {
    vertsGeo.push(v.x, v.y, v.z);
    colorGeo.push(1, 1, 1);
  })

  let geometry = new LineGeometry();
  geometry.setPositions(vertsGeo);
  geometry.setColors(colorGeo);
  let matLine = new LineMaterial({
    linewidth: 4, // in pixels
    vertexColors: true,
    dashed: false,
    dashSize: 1,
    gapSize: 1,
    dashScale: 2,
  });

  matLine.resolution.set(window.innerWidth, window.innerHeight);
  matLine.transparent = true;
  let line = new Line2(geometry, matLine).computeLineDistances();
  switchLineDashedState(matLine, matLine.dashed);
  //@ts-ignore
  line.material.color = color;
  line.renderOrder = -1;
  let polygonShape = shape;
  if (vertices[0].distanceTo(vertices[vertices.length - 1]) < 0.00001)
    polygonShape = true;

  if (shape) {
    vertices[0] = vertices[vertices.length - 1];
  }

  return ({
    lineMesh: line,
    vertices: meshVerts,
    type: type,
    selected: false,
    area: area,
    shape: polygonShape,
    hasCurve: hasCurve,
    innerMesh: innerMesh,
  })
}

export function switchLineDashedState(material: LineMaterial, dashed: boolean) {
  material.dashed = dashed;

  if (material.dashed) {
    material.defines.USE_DASH = "";
  }
  else {
    delete material.defines.USE_DASH;
  }
  material.needsUpdate = true;
}

export function checkHolePolygon(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      l.polygons.forEach(hp => {
        if (p !== hp) {
          if (p.area > hp.area) {
            if (p.shape && hp.vertices.length > 3 && polygonInOtherPolygon(p.vertices, hp.vertices)) {
              hp.motherPolygon = p;
              hp.area *= -1;
            }
          }
        }
      });
    });
  });
}

function polygonInOtherPolygon(vertices1: THREE.Vector3[], vertices2: THREE.Vector3[]) {
  let coord: number[][] = [];
  vertices1.forEach(v => {
    coord.push([v.x, v.y]);
  })

  var polygon = turf.polygon([coord]);
  let v2inv1 = turf.inside([vertices2[0].x, vertices2[0].y], polygon);

  vertices2.forEach(v => {
    v2inv1 = turf.inside([v.x, v.y], polygon) && v2inv1;
  });

  return v2inv1;
}

function getScale(unit: Unit) {
  let scale = 0.001;

  switch (unit) {
    case Unit.Millimeters:
      scale = 0.001;
      break;
    case Unit.Meters:
      scale = 1;
      break;
    case Unit.Inches:
      scale = 39.3701;
    default:
      break;
  }
  return scale;
}

export function dataParsing(data: string, unit: Unit = Unit.Millimeters) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let entities = dxf.entities;

  let scale = getScale(unit); // mm -> m
  let layers = dxf.tables['layer'].layers;
  let layerArray = new Array<ConverterLayer>();
  // App.stage !== "prod" && console.log(dxf.tables['layer'].layers);
  //get layers
  _.forEach(dxf.tables['layer'].layers, (v, k) => {
    layerArray.push({
      name: v.name,
      color: v.color,
      colorIndex: v.colorIndex,
      frozen: v.frozen,
      visible: v.visible,
      polygons: [],
      selected: false,
      isSinglePolygon: false,
      z_index: 0,
      errorLayer: false,
    });
  });

  let bbox = new THREE.Box2();
  entities.forEach(e => {
    if (e.vertices) {
      e.vertices.forEach(v => {
        bbox.expandByPoint(new THREE.Vector2(v.x, v.y));
      });
    }
  })

  let center = new THREE.Vector2();
  bbox.getCenter(center);
  center.multiplyScalar(scale);

  //get polygons
  entities.forEach(e => {
    let verts = [];
    let l = layerArray.find(layer => layer.name === e.layer);
    let entitiesBbox = new THREE.Box2();
    let boxSize = new THREE.Vector2();
    switch (e.type) {
      case entityType.LWPOLYLINE:
      case entityType.POLYLINE:
        for (let j = 0; j < e.vertices.length; j++) {
          let x = Number((e.vertices[j].x * scale - center.x).toFixed(4));
          let y = Number((e.vertices[j].y * scale - center.y).toFixed(4));
          verts.push(new THREE.Vector3(x, y, e.vertices[j].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x, y));
        }
        if (e.shape) {
          let x = Number((e.vertices[0].x * scale - center.x).toFixed(4));
          let y = Number((e.vertices[0].y * scale - center.y).toFixed(4));
          verts.push(new THREE.Vector3(x, y, e.vertices[0].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x, y));
        }
        entitiesBbox.getSize(boxSize);
        if (l && (boxSize.x > 0.0001 || boxSize.y > 0.0001)) {
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape ? true : false));
        }
        break;

      case entityType.LINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(Number((e.vertices[j].x * scale - center.x).toFixed(4)), Number((e.vertices[j].y * scale - center.y).toFixed(4)), e.vertices[j].bulge));
        }
        if (l && verts[0].distanceTo(verts[1]) > 0.001)
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape));
        break;

      default:
        console.log(e);
        break;
    }
  });

  checkHolePolygon(layerArray);
  for (let i = 0; i < layerArray.length;) {
    if (layerArray[i].polygons.length === 0) {
      layerArray.splice(i, 1);
    }
    else {
      i++;
    }
  }
  return layerArray.sort((a, b) => a.name.localeCompare(b.name));
}

export function parsingUpdateFile(data: string) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let entities = dxf.entities;
  let layerArray = new Array<ConverterLayer>();

  //get layers
  _.forEach(dxf.tables['layer'].layers, (v, k) => {
    if (v.name === 'BOUNDRY' ||
      v.name === 'BUILDING' ||
      v.name === 'FIELD' ||
      v.name === 'text' ||
      v.name === '0') {
      console.log('무시 하는 레이어' + v.name);
    }
    else
      layerArray.push({
        name: v.name,
        color: v.color,
        colorIndex: v.colorIndex,
        frozen: v.frozen,
        visible: v.visible,
        polygons: [],
        selected: false,
        isSinglePolygon: false,
        z_index: 0,
        errorLayer: false,
      });
  });

  let bbox = new THREE.Box2();
  entities.forEach(e => {
    if (e.vertices) {
      e.vertices.forEach(v => {
        bbox.expandByPoint(new THREE.Vector2(v.x, v.y));
      });
    }
  })

  remakeEntities(entities, dxf, layerArray);

  return layerArray;
}

function remakeEntities(entities: entity[], dxf: dxf, layerArray: ConverterLayer[], position: THREE.Vector3 = new THREE.Vector3(0)) {
  let layers = dxf.tables['layer'].layers;
  let blocks = dxf.blocks;

  entities.forEach(e => {
    let verts = [];
    let l = layerArray.find(layer => layer.name === e.layer);
    let entitiesBbox = new THREE.Box2();
    let boxSize = new THREE.Vector2();
    switch (e.type) {
      case entityType.LWPOLYLINE:
      case entityType.POLYLINE:
        for (let j = 0; j < e.vertices.length; j++) {
          let x = Number((e.vertices[j].x).toFixed(4));
          let y = Number((e.vertices[j].y).toFixed(4));
          verts.push(new THREE.Vector3(x + position.x, y + position.y, e.vertices[j].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x + position.x, y + position.y));
        }
        if (e.shape) {
          let x = Number((e.vertices[0].x).toFixed(4));
          let y = Number((e.vertices[0].y).toFixed(4));
          verts.push(new THREE.Vector3(x + position.x, y + position.y, e.vertices[0].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x + position.x, y + position.y));
        }
        entitiesBbox.getSize(boxSize);
        if (l && (boxSize.x > 0.0001 || boxSize.y > 0.0001)) {
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape ? true : false));
        }
        break;

      case entityType.LINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(Number((e.vertices[j].x + position.x).toFixed(4)), Number((e.vertices[j].y + position.y).toFixed(4)), e.vertices[j].bulge));
        }
        if (l && verts[0].distanceTo(verts[1]) > 0.001)
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape));
        break;
      case entityType.INSERT:
        //@ts-ignore
        remakeEntities(blocks[e.name].entities, dxf, layerArray, e.position);
        break;
      default:
        console.log(e);
        break;
    }
  });
}

export async function asyncFileRead(fl: FileList) {
  let reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    if (fl[0]) {
      reader.readAsArrayBuffer(fl[0]);
      reader.onload = function () {
        // @ts-ignore 
        let data = iconv.decode(Buffer.from(this.result), 'utf-8');
        resolve(data);
      };
    }
  });
}

export async function asyncOneFileRead(file: any) {
  let reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      // @ts-ignore 
      let data = iconv.decode(Buffer.from(this.result), 'utf-8');
      resolve(data);
    };
  });
}

function makeInsertEntity(entity: any) {
  let position = new THREE.Vector3(0);
  // let vertScale = getScale(dataUnit); // mm -> m

  if (entity.position) {
    position = new THREE.Vector3(entity.position.x, entity.position.y, entity.position.z);
  }

  let scale = new THREE.Vector3(0);
  scale.x = entity.xScale ? entity.xScale : 1;
  scale.y = entity.yScale ? entity.yScale : 1;
  scale.z = entity.zScale ? entity.zScale : 1;

  if (entity.scale)
    scale = entity.scale;

  let insert: ConverterInsert = {
    layer: entity.layer,
    position: position,
    scale: scale,
    rotate: entity.rotation ? entity.rotation : 0,
    type: entity.type,
    name: entity.name,
  }
  // console.log(entity, insert);
  return insert
}

function makeUnitEntit(entity: any, color: number) {
  let verts: THREE.Vector3[] = [];
  // let scale = getScale(dataUnit); // mm -> m

  entity.vertices.forEach((v: any) => {
    verts.push(new THREE.Vector3(v.x, v.y, v.z ? v.z : 0));
  })
  if (entity.shape)
    verts.push(verts[0]);

  let unit: ConverterUnit = {
    layer: entity.layer,
    polygon: makePolygon(verts, new THREE.Color().set(color), entity.type, entity.shape ? true : false),
    type: entity.type,
    verts: verts,
    color: color,
    shape: entity.shape ? true : false,
  }
  // console.log(entity, unit);
  return unit;
}

function makeBuilding(e: ConverterEntity, blocks: ConverterBlock[]) {
  //@ts-ignore
  let block = blocks.find(cb => cb.name === e.name);
  let pos = new THREE.Vector3(0);
  let scale = new THREE.Vector3(0);
  let rotate = 0;
  let name = '';
  if (block) {
    //@ts-ignore
    if (e.type === entityType.INSERT) {
      pos = (e as ConverterInsert).position;
      scale = (e as ConverterInsert).scale;
      rotate = (e as ConverterInsert).rotate;
      name = (e as ConverterInsert).name;
    }

    if (block.type === BlockType.group) {
      let newPart = new BuildingGroup(block!);
      newPart.SetPosition(pos);
      newPart.SetScale(scale);
      newPart.RotateWithDegrees(rotate);
      newPart.SetName(name);

      block!.entities.forEach(en => {
        let compenent = makeBuilding(en, blocks);
        if (compenent) {
          newPart.AddNewPart(compenent);
          newPart.renderGroup.add(compenent.renderGroup);
        }
      })
      return newPart;
    }
    else if (block.type === BlockType.house) {
      let house = new BuildingHouseUnit(block!);
      house.SetPosition(pos);
      house.SetScale(scale);
      house.RotateWithDegrees(rotate);
      house.SetName(name);
      let splits = name.split('_');
      house.SetExclusiveArea(Number(splits[1]) ? Number(splits[1]) : 0);
      house.SetServiceArea(Number(splits[2]) ? Number(splits[2]) : 0);
      house.SetCommonWallArea(Number(splits[3]) ? Number(splits[3]) : 0);

      return house;
    }
    else {
      let core = new BuildingCoreUnit(block!);
      core.SetPosition(pos);
      core.SetScale(scale);
      core.RotateWithDegrees(rotate);
      core.SetName(name);

      let splits = name.split('_');
      core.SetArea(Number(splits[1]) ? Number(splits[1]) : 0);

      return core;
    }
  }
}

export function blockParsing(data: string, outputData: BlockParsingData, dataUnit: Unit = Unit.Millimeters) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let blocks = dxf.blocks;
  let layers = dxf.tables['layer'].layers;
  let entities = dxf.entities;
  let unitScale = getScale(dataUnit); // mm -> m
  console.log(dxf);
  let converterBlocks: ConverterBlock[] = [];

  for (const block of Object.values(blocks)) {
    let type = BlockType.group;
    if (block.name.indexOf('*') === -1) {
      let entities: ConverterEntity[] = [];
      block.entities.forEach(e => {
        switch (block.entities[0].type) {
          case 'INSERT':
            entities.push(makeInsertEntity(e));
            break;
          case 'LWPOLYLINE':
          case 'LINE':
            entities.push(makeUnitEntit(e, layers[e.layer].color));
            break;
          default:
            break;
        }
      })

      switch (block.entities[0].type) {
        case 'INSERT':
          type = BlockType.group;
          break;
        case 'LWPOLYLINE':
        case 'LINE':
          if (block.name.indexOf('C') > -1 || block.name.indexOf('c') > -1)
            type = BlockType.core;
          else if (block.name.indexOf('H') > -1 || block.name.indexOf('h') > -1)
            type = BlockType.house;
          break;
        default:
          break;
      }

      let newBlock = new ConverterBlock();
      newBlock.entities = entities;
      newBlock.name = block.name;
      newBlock.name2 = block.name2;
      newBlock.layer = block.layer;
      //@ts-ignore
      newBlock.position = new THREE.Vector3(block.position.x, block.position.y, block.position.z);
      newBlock.type = type;

      converterBlocks.push(newBlock);
    }
  }

  entities.forEach(e => {
    //@ts-ignore
    if (e.name && (e.name.indexOf('site') > -1 || e.name.indexOf('SITE') > -1)) {
      //@ts-ignore
      let block = converterBlocks.find(cb => cb.name === e.name);
      if (block) {
        let field = new ConverterField(block, FieldType.site);
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
        }
        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }
        //@ts-ignore
        let names = e.name.split('_');
        if (names.length > 0)
          field.setArea(names[1]);

        outputData.fields.push(field);
      }
    }
    //@ts-ignore
    else if (e.name && (e.name.indexOf('road') > -1 || e.name.indexOf('ROAD') > -1)) {
      //@ts-ignore
      let block = converterBlocks.find(cb => cb.name === e.name);
      if (block) {
        let field = new ConverterField(block, FieldType.road);
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
        }
        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }

        outputData.fields.push(field);
      }
    }
    //@ts-ignore
    else if (e.name && (e.name.indexOf('ground') > -1 || e.name.indexOf('GROUND') > -1)) {
      //@ts-ignore
      let block = converterBlocks.find(cb => cb.name === e.name);
      if (block) {
        let field = new ConverterField(block, FieldType.vacancyInside);
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
        }
        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }
        outputData.fields.push(field);
      }
    }
    //@ts-ignore
    else if (e.name && (e.name.indexOf('special') > -1 || e.name.indexOf('SPECIAL') > -1)) {
      //@ts-ignore
      let block = converterBlocks.find(cb => cb.name === e.name);
      if (block) {
        let field = new ConverterField(block, FieldType.vacancyOutside);
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
        }
        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }
        outputData.fields.push(field);
      }
    }
    //@ts-ignore
    else if (e.name && (e.name.indexOf('area line') > -1 || e.name.indexOf('AREA LINE') > -1)) {
      //@ts-ignore
      let block = converterBlocks.find(cb => cb.name === e.name);
      if (block) {
        let field = new ConverterField(block, FieldType.centerLineOfRoad);
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
        }
        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }
        outputData.fields.push(field);
      }
    }
    else if (e.layer !== 'FIELD' && e.layer !== 'BOUNDRY' && e.layer !== 'BUILDING' && e.layer !== 'TEXT'
      && e.layer !== 'VOID' && e.layer !== 'ROAD') {
      let build = new ConverterBuilding();
      if (e.type === 'INSERT') {
        console.log(e);
        //@ts-ignore
        build.name = e.name;
        //@ts-ignore
        if (e.position) {
          //@ts-ignore
          build.setPosition(e.position);
        }

        //@ts-ignore
        if (e.xScale) {
          //@ts-ignore
          build.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
        }

        //@ts-ignore
        if (e.rotation) {
          //@ts-ignore
          build.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
        }

        //@ts-ignore
        let block = converterBlocks.find(cb => cb.name === e.name);
        if (block) {
          block.entities.forEach(e => {
            let part = makeBuilding(e, converterBlocks);
            if (part) {
              build.parts.push(part);
              //@ts-ignore
              build.renderGroup.add(part.renderGroup);
            }
          })
        }
        build.resetPartsElements();
        outputData.buildings.push(build);

      }
    }
  })

  outputData.buildings.forEach(b => {
    b.setUnitScale(unitScale);
  });
  outputData.fields.forEach(f => {
    f.setUnitScale(unitScale);
  })

  return converterBlocks;
}

function MakeBuildingWithMetaData(e: any, blocks: ConverterBlock[]) {
  let block = blocks.find(cb => cb.name === e.name);
  let pos = e.position;
  let scale = e.scale;
  let rotate = e.rotate;
  let name = e.name;

  if (block!.type === BlockType.group) {
    let newPart = new BuildingGroup(block!);
    newPart.SetPosition(pos);
    newPart.SetScale(scale);
    newPart.RotateWithDegrees(rotate);
    newPart.SetName(name);

    e.parts.forEach((p: any) => {
      let compenent = MakeBuildingWithMetaData(p, blocks);
      newPart.AddNewPart(compenent!);
    })
    return newPart;
  }
  else if (block!.type === BlockType.house) {
    let house = new BuildingHouseUnit(block!);
    house.SetPosition(pos);
    house.SetScale(scale);
    house.RotateWithDegrees(rotate);
    house.SetName(name);

    house.SetExclusiveArea(e.exclusiveArea);
    house.SetCommonWallArea(e.commonWallArea);
    house.SetServiceArea(e.serviceArea);
    house.SetBalconyOver150cm(e.balconyOver150cm);

    return house;
  }
  else {
    let core = new BuildingCoreUnit(block!);
    core.SetPosition(pos);
    core.SetScale(scale);
    core.RotateWithDegrees(rotate);
    core.SetName(name);

    core.SetArea(e.area);

    return core;
  }
}

export async function loadMetaFile(metaPath: string, buildings: ConverterBuilding[], dataUnit: Unit = Unit.Millimeters) {
  let meta = await getS3Json(`${metaPath}/meta.json`);
  let converterBlocks: ConverterBlock[] = [];
  let blockMeta = meta.blocks;
  let buildingMeta = meta.entities;

  blockMeta.forEach((bm: any) => {
    let newBlock = new ConverterBlock();
    newBlock.layer = bm.layer;
    newBlock.name = bm.name;
    newBlock.name2 = bm.name2;
    newBlock.position = bm.position;
    newBlock.type = bm.type;

    bm.entities.forEach((e: any) => {
      switch (e.type) {
        case 'INSERT':
          newBlock.entities.push(makeInsertEntity(e));
          break;
        case 'LWPOLYLINE':
        case 'LINE':
          newBlock.entities.push(makeUnitEntit(e, e.color));
          break;
        default:
          break;
      }
    })
    converterBlocks.push(newBlock);
  });

  buildingMeta.forEach((bm: any) => {
    let build = new ConverterBuilding();
    build.name = bm.name;
    build.setPosition(bm.position);

    bm.parts.forEach((p: any) => {
      let part = MakeBuildingWithMetaData(p, converterBlocks);
      build.parts.push(part);
      build.renderGroup.add(part.renderGroup);
    })
    build.setUnitScale(getScale(dataUnit));

    buildings.push(build);
  })

  return converterBlocks;
}
