import React, { Component, Fragment } from 'react';
import './css/ProjectFormBuildingDrawer.scss';

import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton } from '@material-ui/core';
import { ProjectFormPropsBase } from './ProjectInput';
import { ProjectBuildingShapeType, Project, ProjectHousingPlanType } from './model/Project';
import { default as _ } from 'lodash';
import AWSModule from './AWSModule';
import { buildingShapeTypeToString } from './Utils';
import App from './App';
import S3Image from './S3Image';
import { MyBuildingShapeType } from './ProjectFormHousing';
import * as URI from "uri-js";
import { housingPlaneType } from './CADConverter/ProjectDataStruct';

export interface ProjectFormBuildingDrawerProps extends ProjectFormPropsBase {
  setOpen: (open: boolean) => void;
  open: boolean;
}
export interface ProjectFormBuildingDrawerState {}

export default class ProjectFormBuildingDrawer extends Component<ProjectFormBuildingDrawerProps, ProjectFormBuildingDrawerState> {
  render () {
    return (
      <div className="ProjectFormBuildingDrawer">
        <div className="header">
          <div className="title">선택된 타입</div>
          <div className="count">
            <div className="type buildit-type">BUILDIT 동타입 <span className="font font-special m-l-sm">{this.props.currentProject.building_shape_type && this.props.currentProject.building_shape_type.length || 0}개</span></div>
            <span className="m-l-sm m-r-sm">|</span>
            <div className="type my-ty+pe">나의 동평면 <span className="font font-special m-l-sm">{this.props.currentProject.my_building_shape_type && this.props.currentProject.my_building_shape_type.length || 0}개</span></div>
          </div>
        </div>
        <div className="content">
          <div className="error-section">
          {
            Object.values(this.props.projectErros!.errs).filter(e => e!.subDetailType === "BUILDING").length > 0 &&
            <div className="font font-error font-12px" style={{width: "295px", marginRight: "40px"}}>최소 1개 이상의 BUILDIT 동타입 또는 나의 동평면을<br />선택 해주세요</div>
          }
          </div>
          <div className="title">BUILDIT 동타입</div>
          {
            this.props.currentProject.building_shape_type &&
            this.props.currentProject.building_shape_type.length === 0 &&
            <div className="empty">
              선택된 BUILDIT 동타입이 없습니다
            </div> 
            ||
            this.props.currentProject.building_shape_type!.map(t => {
              return <SelectedBuildingType key={t.figures! + t.houses_number!} direction="horizontal" item={t} {...this.props}
                      onClick={
                        (t: ProjectBuildingShapeType[]) => {
                          let housingRateForMyBuildingShapeType = this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0);
                          let housingRateForHousingPlanType = this.props.currentProject.housing_plan_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0);
                          let totalHousingRate = t.length === 0 ? housingRateForMyBuildingShapeType : housingRateForMyBuildingShapeType + housingRateForHousingPlanType;

                          this.props.onUpdateProject({
                            auto_setting: false,
                            building_shape_type: t,
                            housing_plan_type: t.length === 0 ? [] : this.props.currentProject.housing_plan_type,
                            housing_rate: totalHousingRate
                          })
                        }
                      } 
                    />
            })
          }
          <div className="title">나의 동평면</div>
          {
            this.props.currentProject.my_building_shape_type &&
            this.props.currentProject.my_building_shape_type.length === 0 &&
            <div className="empty">
              선택된 나의 동평면이 없습니다
            </div> 
            ||
            this.props.currentProject.my_building_shape_type!.map(t => {
              return <SelectedMyBuildingType key={t.id} direction="horizontal" item={t} {...this.props}
                      onClick={
                        (t: MyBuildingShapeType[]) => {
                          let housingRateForMyBuildingShapeType = t!.map(r => r.proportion!).reduce((a, b) => a + b, 0);
                          let housingRateForHousingPlanType = this.props.currentProject.housing_plan_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0);
                          let totalHousingRate = housingRateForMyBuildingShapeType + housingRateForHousingPlanType;

                          this.props.onUpdateProject({
                            auto_setting: false,
                            my_building_shape_type: t,
                            housing_rate: totalHousingRate
                          })
                        }
                      } 
                    />
            })
          }
        </div>
      </div>
    );
  }
}

export interface SelectedBuildingTypeProps {
  item: ProjectBuildingShapeType;
  onClick?: Function;
  currentProject?: Project;
  className?: string;
  direction: "vertical" | "horizontal";
}
export interface SelectedBuildingTypeState {}
export class SelectedBuildingType extends Component<SelectedBuildingTypeProps, SelectedBuildingTypeState> {
  render () {
    if (this.props.direction === "horizontal") {
      return (
        <div className={`SelectedBuildingType horizontal ${this.props.className}`}>
          <div className="header">
            <IconButton onClick={e => this.onClick(this.props.item)} disableRipple={true} className="close-btn"><CloseIcon /></IconButton>
          </div>
          <div className="body">
            <div className="img">
              <img src={`/img/building_types/square/${this.props.item.figures}_${this.props.item.houses_number}.png`} />
            </div>
            <div className="info">
              <div className="name">{buildingShapeTypeToString(this.props.item.figures!)}</div>
              <div className="key-value">
                <div className="key">세대 수</div>
                <div className="value">{this.props.item.houses_number}세대</div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`SelectedBuildingType vertical ${this.props.className}`}>
          <div className="header">
            {/* <div className="title">{buildingShapeTypeToString(this.props.item.figures!)} {this.props.item.houses_number}세대</div>
            {
              this.props.onClick &&
              <IconButton onClick={e => this.onClick(this.props.item)} disableRipple={true} className="close-btn"><CloseIcon /></IconButton>
            } */}
          </div>
          <div className="img">
            <img src={`/img/building_types/square/${this.props.item.figures}_${this.props.item.houses_number}.png`} />
          </div>
          <div className="info">
            <div className="name">{buildingShapeTypeToString(this.props.item.figures!)}</div>
            <div className="key-value">
              <div className="key">세대 수</div>
              <div className="value">{this.props.item.houses_number}세대</div>
            </div>
          </div>
        </div>
      )
    }
  }
  onClick = (item: ProjectBuildingShapeType) => {
    if (this.props.currentProject) {
      const t = _.cloneDeep(this.props.currentProject.building_shape_type);
      t!.splice(t!.findIndex((e => e.figures === item.figures && e.houses_number === item.houses_number)), 1);
      this.props.onClick && this.props.onClick(t);
    }
  }
}

export interface SelectedMyBuildingTypeProps {
  item: any;
  onClick?: Function;
  currentProject?: Project;
  className?: string;
  direction: "vertical" | "horizontal";
}
export interface SelectedMyBuildingTypeState {
  item?: MyBuildingShapeType,
}
export class SelectedMyBuildingType extends Component<SelectedMyBuildingTypeProps, SelectedMyBuildingTypeState> {
  state: SelectedMyBuildingTypeState = {
    item: undefined,
  }
  componentWillMount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingType,
      query: `stage.keyword:${App.tempStage} AND global_id:${this.props.item.id} AND email.keyword:${App.session.email}`,
      sort: [ {created_at: 'desc'} ]
    });

    const rr = r.data;
    if (rr.hits.hits && rr.hits.hits.length > 0) {
      this.setState({ item: rr.hits.hits[0]._source });
    } else {

    }
    
  }
  render () {
    // const s3 = await new AWSModule("S3").connect();
    if (this.state.item !== null && this.state.item !== undefined) {
      if (this.props.direction === "horizontal") {
        return (
          <div className={`SelectedMyBuildingType horizontal ${this.props.className}`}>
            <div className="header">
              <div className="title">
                <span className="id">{this.state.item.user_id}</span>
                <img src="/img/my_icon.png" />
              </div>
              <IconButton onClick={e => this.onClick(this.props.item)} disableRipple={true} className="close-btn"><CloseIcon /></IconButton>
            </div>
            <div className="body">
              <div className="img">
                <S3Image s3={{
                    Bucket: App.S3Bucket.MyBuildingType,
                    Key: (URI.parse(this.state.item.img_path!, { absolutePath: true }).path as string).substring(1)
                }}/>
              </div>
              <div className="info">
                <div className="name" title={this.state.item.name}>{this.state.item.name}</div>
                <div className="key-value">
                  <div className="key">세대 수</div>
                  <div className="value">{this.state.item.houses_number}세대</div>
                </div>
                <div className="key-value">
                  <div className="key">평균 전용 면적</div>
                  <div className="value">{Number(this.state.item.total_exclusive_area! / this.state.item.houses_number!).toFixed(2)}㎡</div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={`SelectedMyBuildingType vertical ${this.props.className}`}>
            <div className="header">
              <div className="title">
                <span className="id">{this.state.item.user_id}</span>
                <img src="/img/my_icon.png" />
              </div>
              {
                this.props.onClick &&
                <IconButton onClick={e => this.onClick(this.props.item)} disableRipple={true} className="close-btn"><CloseIcon /></IconButton>
              }
            </div>
            <div className="img">
              <S3Image s3={{
                  Bucket: App.S3Bucket.MyBuildingType,
                  Key: (URI.parse(this.state.item.img_path!, { absolutePath: true }).path as string).substring(1)
              }}/>
            </div>
            <div className="info">
              <div className="name" title={this.state.item.name}>{this.state.item.name}</div>
              <div className="key-value">
                <div className="key">세대수</div>
                <div className="value">{this.state.item.houses_number || 0}세대</div>
              </div>
              <div className="key-value">
                <div className="key">평균 전용 면적</div>
                <div className="value">{Number(this.state.item.total_exclusive_area! / this.state.item.houses_number!).toFixed(2)}㎡</div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div></div>
      )
    }
  }
  onClick = (item: any) => {
    if (this.props.currentProject) {
      const t = _.cloneDeep(this.props.currentProject.my_building_shape_type);
      t!.splice(t!.findIndex((e => e.id === item.id)), 1);
      this.props.onClick && this.props.onClick(t);
    }
    // this.props.onUpdateProject({
    //   my_building_shape_type: t
    // })
  }
}