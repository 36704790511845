import React, { Component } from 'react';
// import logo from './logo.svg';
// import './css/App.scss';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import { APIClient } from './api';
import * as api from './api'

import AWS from 'aws-sdk';
import GA4React, { useGA4React } from 'ga-4-react';

// import { Visualizer } from './Visualizer';
// import ProjectForm from './ProjectForm';
// import ProjectList from './ProjectList';
import Home from './Home';
import Login from './Login';
// import ProjectResult from './legacy/ProjectResult';
import { convertColorToString } from 'material-ui/utils/colorManipulator';
import TestProject from './testProejct';
import { UserDatabaseClient } from '@teneleven/protocols-ts-web/lib/db_grpc_web_pb';
import { db } from '@teneleven/protocols-ts-web';
import { UserKeyJS } from '@teneleven/protocols-ts-web/lib/db';
import { UserJS } from '@teneleven/protocols-ts-web/lib/user';
import { number } from 'prop-types';
import BuilditTemplate from './BuilditTemplate';
import ProjectForm from './ProjectForm';
import ProjectList from './ProjectList';
import RegistrationUser from './RegistrationUser';
import MyPage, { pageContent } from './MyPage';
import { ProjectJS } from '@teneleven/protocols-ts-web/lib/project';
import Test2 from './test2';
import ProjectResult from './ProjectResult';
import Landing from './Landing';
import ProjectPayment from './ProjectPayment';
import AWSModule from './AWSModule';
import UserConfirm from './UserConfirm';
import CustomerSupport from './CustomerSupport';
import ResetPassword from './ResetPassword';
import TOSPage from './TOSPage'

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Visualizer } from './Viewer/Visualizer';
import { SampleVisualizer } from './sample/Viewer/Visualizer';
import * as BuildingTypeConverter from './CADConverter/BuildingTypeConverter';
import * as BuildingAreaConverter from './CADConverter/BuildingAreaConverter';
import * as ComplexConverter from './CADConverter/ComplexConverter';
import * as MyTypeBlockConverter from './CADConverter/MyTypeBlockVersionConverter';
import * as MyPlaneBlockConverter from './CADConverter/MyPlanBlockVersionConverter';
import * as MySiteBlockConverter from './CADConverter/MySiteBlockVersionConverter';
import UnconfirmedUser from './UnconfirmedUser';
import SampleProjectResult from './sample/SampleProjectResult';
import DesignerDownloadPage from './DesignerDownloadPage';
import NotFoundPage from './NotFoundPage';
import PageLoading from './PageLoading';

import Axios from 'axios';
import { AxiosRequestConfig, AxiosInstance } from 'axios';

// production만 적용
if (window.location.host.split('.')[0] === "www") {
  const ga4react = new GA4React('G-EGBSZ6B5P9');
  (async () => {
    await ga4react.initialize();
  })();
}

interface AppProps {
}
interface AppState {
  session?: UserJS;
  mapLoaded: boolean;
  sessionLoaded: boolean;
}

class App extends Component {
  state: AppState = {
    session: undefined,
    mapLoaded: false,
    sessionLoaded: false,
  }

  static projectFieldWithoutWKT = ["stage", "project_id", "user_project_id",
    "copy_project_id", "email", "version", "status", "finished_ai", "project_type",
    "project_name", "project_address", "project_use_district", "building_type",
    "project_explanation", "ordering_companay", "ordering_manager", "drafter",
    "drafter_contact_number", "design_stage", "design_office", "builder", "project_site_type",
    "road_value", "field_info_pnu", "building_shape_type", "housing_plan_type",
    "my_building_shape_type", "project_site_area", "vacancy_inside_area", "vacancy_outside_area", "building_land_ratio",
    "floor_area_ratio", "floor_height", "reports_number", "building_stories_max", "building_stories_min", "building_stories_avg",
    "building_stories_avg_type", "setback_regulation_from_north_less_9m", "setback_regulation_from_north_less_9m_type",
    "setback_regulation_from_north_more_9m", "setback_regulation_from_north_more_9m_type", "setback_regulation_from_site_boundary",
    "distance_between_window_opaque_walls", "distance_between_side_opaque_walls", "distance_between_side_walls", "setback_building_line_apartment",
    "setback_building_line_row_house", "setback_building_line_multi_house", "setback_building_line_officetel", "setback_site_boundary_apartment",
    "setback_site_boundary_row_house", "setback_site_boundary_multi_house", "setback_site_boundary_officetel", "setback_building_line_city_apartment",
    "setback_building_line_city_row_house", "setback_building_line_city_multi_house", "setback_site_boundary_city_apartment",
    "setback_site_boundary_city_row_house", "setback_site_boundary_city_multi_house", "view_type_river", "view_type_green",
    "view_type_sky", "view_type_landmark", "daylight_hours_demanded_avg", "daylight_hours_proportion_less_avg", "created_at",
    "modified_at", "deleted_at", "viewed_at", "canceled_at", "queued_at", "run_at", "finished_at", "discountable", "deleted",
    "discarded", "favorite", "auto_setting", "auto_name", "auto_road", "sigungu", "solver_type", "selected_my_building_plan_id",
    "selected_my_site_id", "multi_select_project_site", "housing_rate"
  ];
  static self: App;

  // @ts-ignore
  static naver: typeof naver = window.naver;  
  static ENV = window.location.host.split('.')[0];
  
  static get getStage() {
    switch(App.ENV) {
      case "www":
        return "prod";
      case "test":
        return "test";
      default:
        return "dev";
    }
  }
  static stage: string = App.getStage;
  // static stage: string = "prod"; 
  static tempStage: string = App.stage === "prod" ? "prod" : "dev";
  
  static session: any;
  static jwt: string;

  static get headers() {
    return {
      'Authorization': `Bearer ${App.jwt}`,
    };
  }

  
  // static API_URL = 'https://api-latest.buildit.co.kr/api'
  static API_URL = `https://api-${(App.stage ==='prod') ? 'latest': 'test'}.buildit.co.kr/api`
  static API_Axios: AxiosInstance;

  static get getDesignerURL() {
    switch(App.stage) {
      case "prod":
        return "ws://localhost:30168";
      case "test":
        return "ws://localhost:30167";
      default: // dev
        return "ws://localhost:30166";
    }
  } 
  
static Designer_URL = App.getDesignerURL;

  static search = async (data: any, config: AxiosRequestConfig = {}) => {
    let c = {
      ...config,
    };

    c.headers = {
      ...(c.headers || {}),
      'Authorization': `Bearer ${App.jwt}`,
    };

    c.responseType = 'json';

    const r = await Axios.post(`${App.API_URL}/SearchES`, data, c);

    return r;
  };

  static s3: AWS.S3;

  static USER_POOL_ID: string;
  static COGNITO_CLIENT_ID: string;
  static IDENTITY_POOL_ID: string;
  static APIG_URL: string;

  static DDBTable = {
    MyBuildingType: "platform-buildit-my-building-type-v2",
    MyBuildingPlan: "platform-buildit-my-building-plan-v2",
    MyBuildingSite: "platform-buildit-my-site-v2",
    PlatformBuilditProject: "platform-buildit-project",
    EngineBuilditResult: "engine-buildit-result",
  }
  static S3Bucket = {
    MyBuildingType: "teneleven-platform-my-building-type-v2",
    MyBuildingPlan: "teneleven-platform-my-building-plan-v2",
    MyBuildingSite: "teneleven-platform-my-site-v2",
    EngineResult: "teneleven-engine-result",
  }

  constructor(props: AppProps) {
    super(props);

    if (App.stage === 'prod') {
      App.USER_POOL_ID = 'ap-northeast-2_yfOgF7RDP';
      App.COGNITO_CLIENT_ID = '43eu1mte8sgtdi2eiqdrb1rt0m';
      App.IDENTITY_POOL_ID = 'ap-northeast-2:11f261af-f5d5-4370-b77f-6b073d89f327';
      App.APIG_URL = 'https://ib9y3xipf6.execute-api.ap-northeast-2.amazonaws.com/prod';
    } else {
      App.USER_POOL_ID = 'ap-northeast-2_NMcuWnvIY';
      App.COGNITO_CLIENT_ID = '6lomd8kmejcb91vaairsp5m8kb';
      App.IDENTITY_POOL_ID = 'ap-northeast-2:3ab32aa6-3f37-4bd1-b522-d7640ecc895b';
      App.APIG_URL = 'https://evfghe5z7g.execute-api.ap-northeast-2.amazonaws.com/dev';
    }
  }

  componentDidMount = async () => {
    

    App.self = this;

    while (true) {
      try {
        // @ts-ignore
        if (window.naver.maps.StyleMapTypeOption) {
          this.setState({
            mapLoaded: true,
          });

          break;
        } else {
          throw new Error("no naver map loadded");
        }
      } catch (e) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    }
  }

  async componentWillMount() {
    const noSessionPaths = [
      '/login',
      '/user/register',
      '/user/resetPassword',
      '/user/unconfirmed',
      '/sample',
      '/sampleVisualizer',
      '/landing',
      '/termofservice',
    ]

    if (noSessionPaths.findIndex(path => path === window.location.pathname) < 0) {      
      await this.initSession();
    }
  }

  render() {
    if (this.state.session === undefined) {
      return (
        <Router>
          <Switch>
            {/* <Route exact={true} path="/"  render={(props) => <Landing {...props} />} /> */}
            <Route path="/login" render={(props) => <Login onInitToken={this.onInitToken} {...props} />} />
            <Route path="/user/register" render={(props) => <RegistrationUser {...props} session={this.state.session} />} />
            <Route path="/user/confirm" render={(props) => <UserConfirm {...props} />} />
            <Route path="/user/resetPassword" render={(props) => <ResetPassword {...props} />} />
            <Route path="/user/unconfirmed" render={(props) => <UnconfirmedUser {...props} />} />
            <Route path="/sample" render={(props) => <SampleProjectResult {...props} />} />
            <Route path="/sampleVisualizer" render={(props) => <SampleVisualizer {...props} />} />
            <Route path="/landing" render={(props) => <Landing />} />
            <Route path="/termofservice" render={(props) => <TOSPage {...props} />} />
            {/* <Route path="/designerdownload" render={(props) => <DesignerDownloadPage {...props} session={this.state.session} />} /> */}
            {/*Redirect to="/login"/>*/}
          </Switch>
        </Router>
      )
    } else if (this.state.mapLoaded && this.state.sessionLoaded) {

      
      return (
        <Router>
          <Switch>

            {/* <Route path="/landing" render={(props) => <Landing {...props} />} /> */}
            <Route path="/user/register" render={(props) => <RegistrationUser {...props} session={this.state.session} />} />
            <Route exact={true} path="/" render={(props) => <Home {...props} session={this.state.session} />} />
            <Route path="/project/:projectType/create" render={(props) => <ProjectForm {...props} edit={false} session={this.state.session} />} />
            <Route path="/project/edit" render={(props) => <ProjectForm {...props} edit={true} session={this.state.session} />} />
            <Route path="/project/list" render={(props) => <ProjectList {...props} session={this.state.session} />} />
            <Route path="/project/result" render={(props) => <ProjectResult {...props} session={this.state.session} />} />
            <Route path="/project/payment" render={(props) => <ProjectPayment {...props} session={this.state.session} />} />
            <Route path="/myPage/file" render={(props) => <MyPage {...props} startPageContent={pageContent.mySite} session={this.state.session} />} />
            <Route path="/myPage/point" render={(props) => <MyPage {...props} startPageContent={pageContent.pointUsageList} session={this.state.session} />} />
            <Route path="/myPage/information" render={(props) => <MyPage {...props} startPageContent={pageContent.myInformation} session={this.state.session} />} />
            <Route path="/customerSupport" render={(props) => <CustomerSupport {...props} />} />
            <Route path="/visualizer" render={(props) => <Visualizer {...props} />} />
            <Route path="/cad/myBuildingType" render={() => <BuildingTypeConverter.Scene />} />
            <Route path="/cad/mySite" render={() => <BuildingAreaConverter.Scene />} />
            <Route path="/cad/myBuildingPlan" render={() => <ComplexConverter.Scene />} />
            <Route path="/cad/myTypeBlock" render={() => <MyTypeBlockConverter.Scene />} />
            <Route path="/cad/myPlaneBlock" render={() => <MyPlaneBlockConverter.Scene />} />
            <Route path="/cad/mySiteBlock" render={() => <MySiteBlockConverter.Scene />} />
            <Route path="/sample" render={(props) => <SampleProjectResult {...props} />} />
            <Route path="/sampleVisualizer" render={(props) => <SampleVisualizer {...props} />} />
            <Route path="/termofservice" render={(props) => <TOSPage {...props} />} />
            <Route path="/designerdownload" render={(props) => <DesignerDownloadPage {...props} session={this.state.session} />} />
            {
              /*
              <Route path="/project/create" render={(props) => <ProjectForm {...props} isCreate={true} session={this.state.session}/>} />
              <Route path="/project/edit" render={(props) => <ProjectForm {...props} isCreate={false} session={this.state.session}/>}/>
              <Route path="/project/list" render={(props) => <ProjectList {...props} session={this.state.session} />} />
              <Route path="/project/result" render={(props) => <ProjectResult {...props} session={this.state.session} />} />
              <Route path="/visualizer" render={(props) => <Visualizer {...props} />} />
              */
            }
            {/* <Route path="/test" render={(props) => <Test {...props} />} /> */}
            <Route path="/test2" render={(props) => <Test2 {...props} />} />
            <Route path="/testProject" render={(props) => <TestProject {...props} session={this.state.session} />} />
            <Route path="/404" render={(props) => <NotFoundPage {...props} session={this.state.session} />} />
            <Redirect to="/404" />
          </Switch>
        </Router>
      );
    } else {
      return <PageLoading />
    }
  }

  onInitToken = (token: number) => {
    this.setState({
      token: token,
    });
    window.location.href = "/";
  }

  initSession = async () => {
    await new AWSModule("S3").Reload();
    await App.updateSession();
    App.s3 = (await new AWSModule('S3').connect()).S3!;

    this.setState({
      sessionLoaded: true,
    });
  }

  static updateSession = async () => {
    try {
      const AWS = await new AWSModule("DDB").connect();
      const r = await AWS.Ddb!.get({
        TableName: 'platform-buildit-user',
        Key: {
          "id": AWS.id!,
        }
      }).promise();

      const r2 = await AWS.Ddb!.get({
        TableName: 'platform-buildit-global-dev-PointTable-1QOG3IOBGUWIY',
        Key: {
          "id": AWS.id!,
        }
      }).promise();

      const s = {
        ...r.Item!,
        ...r2.Item!,
      };

      // if (App.stage === 'dev' && window.localStorage.getItem("buildit-email-override")) {
      //   s.email = window.localStorage.getItem("buildit-email-override");
      // }
      
      if (s.paying_type === "INFINITE") {
        const lambda = await new AWSModule("LAMBDA").connect();
        const r3 = await lambda.Lambda!.invoke({
            FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-GetAvaliablePointForPlatinumUser-${App.tempStage}`,
            Payload: JSON.stringify({
              "user_id": s.id,
            })
          }).promise();
        const rr = JSON.parse(r3.Payload as string);
        s.point = rr.body.available_daily_point;
      }
      
      App.session = s;
      App.self.setState({
        session: s
      });
    } catch (e) {
      setTimeout(() => window.location.reload(), 500);
    }
  }

  static logout = async () => {
    const poolData = {
      UserPoolId: App.USER_POOL_ID,
      ClientId: App.COGNITO_CLIENT_ID,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser()!;

    // @ts-ignore
    AWS.config.credentials.clearCachedId();
    cognitoUser.signOut();

    // if (App.self.state.session) {
    //   App.updateSession();
    // }

    App.self.setState({
      session: undefined
    }, async () => {
      await new Promise(resolve => setTimeout(resolve, 100));
      window.location.href = "/login";
    })
  }
}

export default App;
