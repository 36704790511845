

import React, { Component } from 'react';
import './css/MemoIcon.scss';
import { withStyles } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Tooltip } from '@material-ui/core';

const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "#CCCCCC",
    backgroundColor: "#000000",    
    fontSize: "12px",
    lineHeight: '20px',
    padding: '7px',
  }
})(Tooltip);

export interface MemoIconProps {
  tooltip: string;
  className?: string;
  iconClassName?: string;  
  onTooltip?: boolean;
  onText?: boolean;
}



export default class TooltipMemoIcon extends Component<MemoIconProps> {
  
  render() {
    return (
      <TextOnlyTooltip className="Memo memo-tooltip" title={<span style={{ whiteSpace: 'pre-wrap' }}>{this.props.tooltip}</span>}>
        <div style={{width:'20px', height: '20px'}}>
          <DescriptionOutlinedIcon className="MemoIcon icon" {...this.props} />
        </div>
      </TextOnlyTooltip>
    )
  }
}